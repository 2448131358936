import React, { useState, useEffect } from "react";
import DashboardTop from "molecules/DashboardTop";
import CheckInCheckout from "molecules/CheckInCheckout";
import NonStaffDashboard from "molecules/NonStaffDashboard";
import UserLeaveReport from "molecules/UserLeaveReport";
import UserCheckinReport from "molecules/UserCheckinReport";
import { Row, Col } from "antd";
import styled from "@emotion/styled";
import { getLeaveCategoryList } from "redux/selectors";
import { connect } from "react-redux";
import { getUserRoleId } from "redux/selectors";
import UserMonthlyReport from "molecules/UserMonthlyReport";

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function Dashboard({ userRoleId, leaveCategoryList }) {
  const leaveID = leaveCategoryList.find(
    (category) => category.leave_category_id == 3
  );
  return (
    <>
      <DashboardTop />
      {userRoleId === 4 && (
        <>
          <CheckInCheckout />
          <StyledRow gutter={16}>
            <Col md={24} sm={24} xs={24} xl={9}>
              <Col md={24} sm={24} xs={24} xl={24}>
                <UserLeaveReport leaveCategoryList={leaveCategoryList} />
              </Col>
              <Col md={24} sm={24} xs={24} xl={24}>
                <UserMonthlyReport />
              </Col>
            </Col>
            <Col md={24} sm={24} xs={24} xl={15}>
              <UserCheckinReport leaveCategoryList={leaveCategoryList} />
            </Col>
          </StyledRow>
        </>
      )}
      {userRoleId !== 4 && <NonStaffDashboard />}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  leaveCategoryList: getLeaveCategoryList(state),
});

export default connect(mapStateToProps, null)(Dashboard);
