import React from 'react';
import {
  DeleteFilled, EditFilled, EyeFilled
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Modal, Row, Spin, Table } from 'antd';
import Button from 'atoms/Button';
import AddTask from 'organisms/AddTask';
import EditTask from 'organisms/EditTask';
import { useEffect, useState } from 'react';
import { includes, concat, get } from 'lodash'
// Redux
import { CALL_API } from 'common/API';
import { displayErrorModal } from 'common/common';
import { STATUS_CODE } from 'common/Constants';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  toggleProcessingModal
} from 'redux/actions';
import {
  getSelectedDepartmentId, getUserRoleId, getUserInfo
} from 'redux/selectors';
import ViewMore from 'organisms/ViewMore';

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    min-width:200px;
    white-space: normal;
  }
`;

function Task({
  userRoleId,
  selectedDepartmentId,
  updateProcessingModal,
  userInfo
}) {
  const [isTaskModalVisible, toggleTaskModalVisible] = useState(false);
  const [addingTask, toggleAddingTask] = useState(false);
  const [editingTask, toggleEditingTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);

  const columns = [
    {
      title: 'Task Title',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
      className: 'normal-column'
    },
    {
      title: 'Task Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      className: 'normal-column',
      render: (description) => {
        return (
          <>
           <ViewMore value={description} />
          </>
        );
      }
    },
    {
      title: 'Department',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%'
    },
    {
      title: 'Created Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '10%',
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Created Day',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '10%',
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'Due Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Due Day',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    }
  ];

  if (userRoleId !== 4) {
    columns.push({
      title: 'Assigned To',
      dataIndex: 'user_name',
      key: 'user_name',
      width: '15%',
      hidden: userRoleId === 4,
    });
  }

  columns.push({
    title: 'Action',
    dataIndex: 'address',
    key: 'address',
    width: '10%',
    align: 'center',
    render: (_, record) => (
      <>
        {
          userRoleId === 4 ? <EyeFilled className='action-button' onClick={() => viewTask(record, true)} /> : (
            <>
              <EditFilled className='action-button' onClick={() => viewTask(record)} />
              <DeleteFilled className='action-button' onClick={() => deleteTask(record.task_id)} />
            </>
          )
        }
      </>
    ),
  },
  );

  useEffect(() => {
    getTaskList();
  }, [])

  useEffect(() => {
    getTaskList();
  }, [selectedDepartmentId, userRoleId]);

  const getTaskList = async () => {
    setDisplayTaskLoader(true);
    const {
      code,
      tasks
    } = await CALL_API('task-list', 'post', {
      department_id: (userRoleId === 4 ? [get(userInfo, 'staff_department_id', '')] : selectedDepartmentId),
      selected_role_id: userRoleId,
    });
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      let recordList = []
      tasks.map(list => {
        Object.entries(list).forEach(([key, value]) => {
          recordList = concat(recordList, value)
        })
      })
      setTaskList(recordList.reverse());
    }
    setDisplayTaskLoader(false);
  }

  const addTask = () => {
    toggleAddingTask(true);
  }

  const viewTask = (taskObject, isViewOnly = false) => {
    toggleEditingTask(true);
    setTaskDetails({
      ...taskObject,
      isViewOnly
    });
  }

  const deleteTask = (taskId) => {
    Modal.confirm({
      icon: '',
      content: 'Are you sure you want to delete task?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        // updateProcessingModal(true);
        const {
          code,
        } = await CALL_API('delete-task', 'post', {
          task_id: taskId
        });
        // updateProcessingModal(false);
        if (code === STATUS_CODE.TASK_DELETED) {
          getTaskList();
        } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
          displayErrorModal(`Invalid payload. Please try again`);
        }
        getTaskList();
      }
    });
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col span={12} className='page-title-head'>Tasks</Col>
        <Col span={12} className='align-right'>
          {
            userRoleId !== 4 && <Button type='primary' className='add-event' onClick={() => addTask(true)}>Add Task</Button>
          }
        </Col>
      </Row>
      <Spin spinning={displayTaskLoader}>
        <StyledTable
          dataSource={taskList}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='task_id'
          pagination={taskList.length > 20 ? paginationOptions : false}
        />
      </Spin>
      <AddTask
        isModalVisible={addingTask}
        closeModal={() => toggleAddingTask(false)}
        getTaskList={getTaskList}
        title='Add New Task'
        taskDetails={taskDetails}
      />
      <EditTask
        isModalVisible={editingTask}
        closeModal={() => toggleEditingTask(false)}
        getTaskList={getTaskList}
        title='Edit Task'
        taskDetails={taskDetails}
      />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateProcessingModal: toggleProcessingModal,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Task);
