import React from 'react';
import Notifications from 'organisms/Notifications';
import { Card, Modal } from 'antd';
import styled from 'styled-components';
import { CALL_API } from 'common/API';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators  } from 'redux';
import { setNotificationList } from 'redux/actions';

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  .notification-popover-mobile .ant-row:first-child {
    padding-top: 10px;
  }
  .notification-popover-mobile .ant-row:last-child {
    padding-bottom: 10px;
  }
  .avatar-container {
    padding: 10px;
  }
`;

const StyledClearNotification = styled.div`
  text-align: right;
  font-size: 0.9rem;
  padding-bottom: 10px;
  color: #5b5bff;
  cursor: pointer;
`

function Notification({
  saveNotificationList
}) {
  const clearNotifications = () => {
    Modal.confirm({
      icon: '',
      content: 'Are you sure you want to clear notifications',
      okText: 'YES',
      cancelText: 'No',
      onOk: async () => {
        await CALL_API('clear-notification', 'post', {});
        // saveNotificationList([]);
      }
    });
  }

  return (
    <>
      <StyledClearNotification onClick={clearNotifications}>Clear Notification</StyledClearNotification>
      <StyledCard>
        <Notifications />
      </StyledCard>
    </>
  );
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    saveNotificationList: setNotificationList,
  },
  dispatch
);

export default connect(null, mapDispatchToProps)(Notification);
