import React from 'react';
import { Row, Col } from 'antd';
import { Pie } from '@ant-design/plots';
import styled from '@emotion/styled';
import RoundIcon from 'atoms/RoundIcon';
import Icon from 'atoms/Icon';
import { last, map, get, first } from 'lodash';

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  .styled-span{
    color:blue;
    font-weight: normal;
  }
  .styled-circle {
    height: 30px;
    width: 30px;
    svg {
      margin: 4px;
    }
  }
  .report-title {
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left:10px;
    text-transform: uppercase;
  }
  .report-title1 {
    white-space: nowrap;
    font-weight: bold;
    font-size: 0.9rem;
    padding-top: 3px;
  }
  .legends {
    padding-top: 3rem;
    padding-left: 5px;
    div {
      padding-bottom: 5px;
    }
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  padding: 0px 8px;
  margin-right: 10px;
`

function UserLeaveDonut({
  iconName,
  title,
  data,
  LastYearLeaveData
}) {
  const config = {
    width: 150,
    height: 150,
    appendPadding: 6,
    data,
    angleField: 'value',
    colorField: 'type',
    color: map(data, 'color', []),
    radius: 1,
    innerRadius: 0.8,
    legend: false,
    label: false,
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 13
        },
        content: `${get(last(data), 'value', '')}/${get(first(data), 'value', '')}`,
      },
    },
  };
  return (
    <StyledChartContainer>
      <Row>
          <Col span={4}>
            <RoundIcon>
              { iconName && <Icon name={iconName} /> }
            </RoundIcon>
          </Col>
          <Col span={20} className='report-title'>
            {title}
          </Col>
          <Col span={12} className='legends'>
            <div>
              <StyledColorBox color={get(first(data), 'color')}></StyledColorBox>
              <span>
                { get(first(data), 'type', '') } : { data[0].value == null ? "null" : get(first(data), 'value', '') }
              </span>
            </div>
            <div>
              <StyledColorBox color={get(last(data), 'color')}></StyledColorBox>
              <span>
                { get(last(data), 'type', '') } : { data[1].value == null ? "null" : get(last(data), 'value', '') }
              </span>
            </div>
          </Col>
          <Col span={12}>
            <Pie {...config} />
          </Col>
          <br/>
        
      </Row>
  </StyledChartContainer>
  );
}

export default UserLeaveDonut;
