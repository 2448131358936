import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, message } from 'antd';
import Button from 'atoms/Button';
import moment from 'moment';
import styled from '@emotion/styled';
import { utils, writeFileXLSX } from 'xlsx';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getUserList
} from 'redux/selectors';
import { CALL_API } from 'common/API';
import { MONTH_LIST, REPORT_DURATION_TYPES, STATUS_CODE } from 'common/Constants';
import { addDaysinDate, dateInDetail, getKenyanDateTime, getMonthDateRange, getMySqlDate, momentDate, addMonthinDate, differenceBetweenTwoTime, generateHourMinutesFromMinutes } from 'utils/Date';
import COLORS from 'common/Colors';
import { concat, find, get, includes, orderBy, reduce } from 'lodash';

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom :10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color:  ${COLORS.PRIMARY};
  width: initial;
  background:transparent;
`;

function Approval({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  shiftTimeMaster
}) {

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [staffAttendanceList, setStaffAttendanceList] = useState([]);
  const [CSVAttendanceList, setCSVAttendanceList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [staffName, setStaffName] = useState(' ');
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([])

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }

  const STAFF_LIST_ALL = attendanceList.map((val) => {
    return { user_id: val.user_id, name: val.name }
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));

  const filterattentancereport = attendanceList.filter(val => {
    if (form.getFieldValue('staff_name') === '') {
      return val;
    }

    else if (val?.user_id === form.getFieldValue('staff_name')) {
      return val;
    }
  })

  const CSVHeaders = [
    { label: "Staff ID" },
    { label: "Employee Name" },
    { label: "Department" },
    { label: "Checkin Station" },
    { label: "Check-in Date" },
    { label: "Check-in Time" },
    { label: "Check-in Coordinates" },
    { label: "Checkout Station" },
    { label: "Check-out Date" },
    { label: "Check-out Time" },
    { label: "Check-out Coordinates" },
    { label: "Duration (Hours)" },
    { label: "Late time (Hours)" }
  ];

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      width: '7%',
      render: (employee_id) => {
        if (employee_id !== ("" || null || undefined)) {
          return employee_id;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Employee Name',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (name) => {
        if (name !== ("" || null || undefined)) {
          return name;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: '10%',
      render: (department) => {
        if (department !== ("" || null || undefined)) {
          return department;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Checkin Station',
      dataIndex: 'location_name',
      key: 'location_name',
      width: '10%',
      render: (location_name) => {
        if (location_name !== ("" || null || undefined)) {
          return location_name;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Check-in date',
      dataIndex: 'date',
      key: 'date',
      width: '7%',
      render: (date) => {
        if (date === ("" || null || undefined)) return '-';
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Check-in day',
      dataIndex: 'date',
      key: 'date',
      width: '7%',
      render: (date) => {
        if (date === ("" || null || undefined)) return '-';
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'Check-in time',
      dataIndex: 'start_datetime',
      key: 'start_datetime',
      width: '5%',
      render: (start_datetime) => {
        if (start_datetime === '') return '-';
        const { time24 } = dateInDetail(start_datetime);
        return `${time24}`;
      }
    },
    { // New
      title: 'Check-in Coordinates',
      dataIndex: 'start_lat_long',
      key: 'login_latitude',
      width: '10%',
      render: (_, record) => {

        const url1 = 'http://maps.google.com/maps/?q=' + record.login_latitude + "," + record.login_longtitude + ''
        return (
          <div>
            {
              record.login_latitude !== ("" || null || undefined) && record.login_longtitude !== ("" || null || undefined)
                ? (
                  <>
                    <div id='loc'><a href={url1} target='_blank'>{record.login_latitude},{record.login_longtitude}</a></div>

                  </>
                )
                : '-'
            }
          </div>
        )
      }
    },
    {
      title: 'Checkout Station',
      dataIndex: 'logout_location_name',
      key: 'logout_location_name',
      width: '10%',
      render: (logout_location_name) => {
        if (logout_location_name !== null) {
          return logout_location_name;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Checkout Type',
      dataIndex: 'checkout_type',
      key: 'checkout_type',
      width: '7%',
      render: (checkout_type) => {
        if (checkout_type !== null) {
          if (checkout_type === "auto") {
            return 'Auto';
  
          } else {
            return "Manual"
          }

        } else {
          return "-"
        }
       
      }
    },
    {
      title: 'Check-out date',
      dataIndex: 'end_datetime',
      key: 'end_datetime',
      width: '7%',
      render: (end_datetime) => {
        if (end_datetime === null) return '-';
        const dateFormat = moment(end_datetime).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Check-out day',
      dataIndex: 'end_datetime',
      key: 'end_datetime',
      width: '7%',
      render: (end_datetime) => {
        if (end_datetime === null) return '-';
        const getdayAlone = moment(end_datetime).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'Check-out time',
      dataIndex: 'end_datetime',
      key: 'end_datetime',
      width: '5%',
      render: (end_datetime) => {
        if (end_datetime !== null) {
          const { time24 } = dateInDetail(end_datetime);
          return `${time24}`;
        } else {
          return '-';
        }
      }
    },
    {
      title: 'Check-out Coordinates',
      dataIndex: 'end_lat_long',
      key: 'logout_latitude',
      width: '10%',
      render: (_, record) => {
        const url = 'http://maps.google.com/maps/?q=' + record.logout_latitude + "," + record.logout_longtitude + '';
        return (
          <div>
            {
              record.logout_latitude !== null && record.logout_longtitude !== null
                ? (

                  <>
                    <div><a href={url} target='_blank'>{record.logout_latitude},{record.logout_longtitude}</a></div>

                  </>
                )
                : '-'
            }
          </div>
        );
      }
    },
    {
      title: 'Duration (Hours)',
      dataIndex: 'duration',
      key: 'duration',
      width: '7%',
      render: (_, record) => {
        if(record.start_datetime !== null && record.end_datetime !== null){
          let getCheckinCheckoutTime = differenceBetweenTwoTime(record.start_datetime, record.end_datetime);
          let CheckinCheckoutTimeDiff = generateHourMinutesFromMinutes(getCheckinCheckoutTime);
          let getBreakTime = differenceBetweenTwoTime(record.break_start_datetime === null ? 0 : record.break_start_datetime, record.break_end_datetime === null ? 0 : record.break_end_datetime);
          let breakTimeDiff = generateHourMinutesFromMinutes(getBreakTime);
          let getTotalDuration = differenceBetweenTwoTime(breakTimeDiff, CheckinCheckoutTimeDiff);
          let durationDiff = generateHourMinutesFromMinutes(getTotalDuration);     
          return durationDiff;
        }else {
          return '-';
        }
      }
    },
    {
      title: 'Late time (Hours)',
      dataIndex: 'over_time',
      key: 'over_time',
      width: '7%',
    },

  ];

  useEffect(() => {
    getAttendanceReport();
  }, [selectedDepartmentId, userRoleId, form.getFieldValue('duration_type'), form.getFieldValue('start_date'), form.getFieldValue('end_date'), form.getFieldValue('month'), form.getFieldValue('department')]);
  
  // Function To generate report 
  const getAttendanceReport = async () => {
    list()
    return new Promise(async (resolve, reject) => {
      setAttendanceList([]);
      const {
        start_date,
        end_date,
        duration_type,
        month,
        department,
      } = form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), 'index', 0),
      );
      const {
        code,
        attendanceList = []
      } = await CALL_API('attendance-report', 'post', {
        department: (userRoleId === 1 || userRoleId === 6) ? [department] : selectedDepartmentId,
        start_date: getMySqlDate(duration_type === 2 ? startDateOfMonth : start_date),
        end_date: getMySqlDate(duration_type === 2 ? lastDateOfMonth : end_date),
      });
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        let recordList = []
        attendanceList.map(list => {
          Object.entries(list).forEach(([key, value]) => {
            recordList = concat(recordList, value)
          })
        })
        setAttendanceList(orderBy(recordList, ['date'], ['desc']));
        setStaffAttendanceList(orderBy(recordList, ['date'], ['desc']));
      }
    })
  }

  // Function for Restrict past Date in date field

  // const restrictPastDate = (current, element) => {
  //   let customDate = moment().format('YYYY-MM-DD');
  //   if (element === 'end_date') {
  //     return current < moment(form.getFieldValue('created_date'));
  //   }
  //   return current && current < moment(customDate, 'YYYY-MM-DD');
  // }

  // Function for Date validation
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }
  
  // Function to Export table
  const xport = React.useCallback(() => {
    const elt = document.getElementById('Table');
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "attendance-report.xlsx");
  });

  // Function to Prepare CSV 

  // const prepareCSV = async () => {
  //   if (attendanceList.length > 0) {
  //     setCSVAttendanceList(
  //       reduce(attendanceList, function (result, record) {
  //         const { date: day, shortMonthName, year, longDay } = dateInDetail(record.start_datetime);
  //         const { date: end_day, shortMonthName: end_shortMonthName, year: end_year, longDay: end_longDay } = dateInDetail(record.end_datetime);
  //         const durationDiff = differenceBetweenTwoTime(record.start_datetime, record.end_datetime);
  //         const loc = document.getElementById("loc")
  //         result.push({
  //           "Employee ID": record.user_id == "" ? "-" : record.user_id,
  //           "Employee Name": record.name == "" ? "-" : record.name,
  //           // "Department": record.department,
  //           "Checkin Station": record.location_name,
  //           "Check-in Date": `${day} ${shortMonthName} ${year} ${longDay}`,
  //           "Check-in Time": dateInDetail(record.start_datetime).time24,
  //           "Check-in Coordinates": `${record.login_latitude},${record.login_longtitude}`,
  //           "Checkout Station": record.logout_location_name,
  //           "Check-out Date": record.end_datetime !== null ? `${end_day}-${end_shortMonthName}-${end_year} ${end_longDay}` : '-',
  //           "Check-out Time": record.end_datetime !== null ? dateInDetail(record.end_datetime).time24 : '-',
  //           "Check-out Coordinates": (record.logout_latitude !== '' && record.logout_longtitude !== '') ? `${record.logout_latitude},${record.logout_longtitude}` : '-',
  //           "Duration (Hours)": record.duration == "" ? "-" : record.duration,
  //           // "Duration (Hours)": generateHourMinutesFromMinutes(durationDiff),
  //           // "Late time (Hours)": calculateLateTime(record.start_datetime),
  //           "Late time (Hours)": record.over_time == "" ? "-" : record.over_time,

  //         });
  //         return result;
  //       }, [])
  //     );

  //     setTimeout(() => {
  //       csvLinkEl.current.link.click();
  //     });
  //   } else {
  //     message.error(`No data available to download.`);
  //   }
  // }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col xs={24} sm={19} md={19} className='page-title-head'>
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              initialValues={{
                staff_name: '',
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(),)),
                department: '',
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name='duration_type'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder='Select...'
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>


              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Form.Item name='start_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='Start date'
                            // disabledDate={(current) => restrictPastDate(current, 'start_date')} 
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name='end_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='End date'
                            // disabledDate={(current) => restrictPastDate(current, 'end_date')} 
                            onChange={validateDateRange}
                            allowClear={false}
                          // disabled={isViewOnly}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name='month'
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Select...'
                            options={monthList}
                          />
                        </Form.Item>

                      </>
                    )
                  }
                }
              </Form.Item>
              <Form.Item
                name='staff_name'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat({
                    user_id: '',
                    name: 'All Employee'
                  }, STAFF_LIST)}
                  fieldNames={{
                    label: 'name',
                    value: 'user_id'
                  }}
                  onChange={(e) => setStaffName(e)}
                />
              </Form.Item>
              {
                (userRoleId === 1 || userRoleId === 6) && (
                  <Form.Item
                    name='department'
                  >
                    <Select
                      placeholder="Select Department"
                      options={concat({
                        department_id: '',
                        department_name: 'All Departments'
                      }, allDepartmentList)}
                      fieldNames={{
                        label: 'department_name',
                        value: 'department_id'
                      }}
                    />
                  </Form.Item>
                )
              }
              {/* <Button type="primary" htmlType="submit">Filter</Button> */}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className='align-right'>
          {/* <Button type="link" onClick={prepareCSV} disabled={attendanceList.length === 0}>
            Export CSV file
          </Button>
          <CSVLink
            header={CSVHeaders}
            data={CSVAttendanceList}
            filename={"attendance-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          /> */}
          <StyledButton onClick={xport} disabled={filterattentancereport.length === 0}>
            Export XLSX file
          </StyledButton>
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterattentancereport}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='checkin_id'
          pagination={filterattentancereport.length > 20 ? paginationOptions : false}
        />
        {/* Below table is for Export the data */}
        <Table
          style={{ display: 'none' }}
          dataSource={filterattentancereport}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='checkin_id'
          pagination={false}
          id='Table'
        />
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state)
});

export default connect(mapStateToProps, null)(Approval);


