import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button, Select } from 'antd';
import {
  CaretDownOutlined,
} from '@ant-design/icons';

// Redux
import { connect } from 'react-redux';
import { getBreakTypeList } from 'redux/selectors';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
  }
  .ant-modal-title {
    text-align: center;
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-bottom: 75px;
  }
  .ant-modal-footer {
    border-top: none;
    padding-bottom: 20px;
  }
`;

const StyledButton = styled(Button)`
  width: 100px;
`;

const StyledErrorMessage = styled.div`
  color: ${COLORS.ERROR};
  padding: 10px 5px;
`;

function BreakModal({
  isModalVisible,
  closeModal,
  startBreak,
  breakTypeList
}) {
  const [ breakType, setBreakType ] = useState(0);
  const [ errorMessage, setErrorMessage ] = useState('');
  const handleOk = () => {
    if (breakType) {
      startBreak(breakType);
      setErrorMessage('')
    } else {
      setErrorMessage('Select break type');
    }
  };

  useEffect(() => {
    setErrorMessage('')
  }, [isModalVisible])
  

  return (
    <StyledModal
      title="Start Break"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModal}
      width={400}
      footer={[
        <Row key='footer'>
          <Col span={12} className='align-center'>
            <StyledButton onClick={closeModal}>Cancel</StyledButton>
          </Col>
          <Col span={12} className='align-center'>
            <StyledButton onClick={handleOk} type='primary'>Start</StyledButton>
          </Col>
        </Row>
      ]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Select Break"
        options={breakTypeList}
        onChange={breakId => {
          setBreakType(breakId);
        }}
        fieldNames={{
          label: 'break_name',
          value: 'break_id'
        }}
      />

      <StyledErrorMessage>
        {errorMessage}
      </StyledErrorMessage>      
    </StyledModal>
  );
}

const mapStateToProps = state => ({
  breakTypeList: getBreakTypeList(state),
});

export default connect(mapStateToProps, null)(BreakModal);
