import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div`
   padding : 3%;
   min-height: 100%;
   background:#ffffff;
   .version{
     padding: 2%;
     margin-bottom:1%;
   }
   p {
        width: 75%;
        word-spacing:3px;
        letter-spacing:0.5px;
    }
   span{
      font-size:14px;
   }
`;

function ReleasedNote() {

  const versions = [
    {
      title : 'Tymeplus Version 1.2 Release Note',
      Date : '26 Aug 2023',
      content: `Added more features such as Reports Preferences for HOD and ASSISTANT HOD, Added buffer time based on shift, 
      Added Role and Shift in admin portal, Added document delete option, Added sick leave half pay leave category and fixed bugs.`,
      type:`( Current Version )`
    },
    {
        title : 'Tymeplus Version 1.1 Release Note',
        Date : '20 Jan 2023',
        content: `Added more features such as Reports - Late check-in, Early checkout, Absent, Over time, 
        User preference, Multi department, Staff / HOD Dashboard attractive, Added employee filter all the reports,
        User Clear notification changes - read, unread, My Profile update, Face recognition More UI improvements, 
        Admin Portal feature, Fixed Bugs and Performance Running`,
        type:''
   },
   {
        title : 'Tymeplus Version 1.0 Release Note',
        Date : '30 Oct 2022',
        content: `Dashboard, Calender, Approval, Task, Attendance`,
        type : ''
   },

]  
  return (
    <StyledContainer>
        {versions.map((item,index) => {
            return (
                <div className='version'>
                    <h3>{item.title} <span>{item.type}</span></h3>
                    <h6>Release Date : {item.Date}</h6>
                    <br/>
                    <h5>Feature</h5>
                    <p>
                    {item.content}
                    </p>
               </div>
            )
        })}
   
    </StyledContainer>
  )
}

export default ReleasedNote