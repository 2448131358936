import React from 'react';
import { Modal } from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';

// Redux
import { connect } from 'react-redux';
import { getToggleProcessingDrawer } from 'redux/selectors';
import { bindActionCreators  } from 'redux';
import { toggleProcessingModal } from 'redux/actions';
import styled from '@emotion/styled';

const StyledSuccessModal = styled(Modal)`
  .ant-modal-body {
    font-weight: bold;
    padding: 30px 10px;
    padding-left: 40px;
    .loading-message {
      padding-left: 50px;
    }
  }
  .ant-modal-footer {
    border-top: none;
    padding: 0px;
  }
  .anticon-check-circle {
    font-size: 4rem;
    display: block;
    padding: 10px;
    color: #2CFF00;
  }
`;

function ProcessingModal({
  isOpenDrawer,
  updateProcessingModal
}) {
  return (
    <StyledSuccessModal
      title=""
      visible={isOpenDrawer}
      centered
      closable={false}
      keyboard={false}
      maskClosable={false}
      width={350}
      footer={[]}
      onCancel={() => updateProcessingModal(false)}
    >
      <Spin indicator={
        <LoadingOutlined style={{ fontSize: 30 }} spin />
      } />
      <span className='loading-message'>Please wait...</span>
    </StyledSuccessModal>
  );
}

const mapStateToProps = state => ({
  isOpenDrawer: getToggleProcessingDrawer(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
	{
    updateProcessingModal: toggleProcessingModal,
	},
	dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingModal);
