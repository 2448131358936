import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, Tooltip, Modal, message, Menu, Dropdown } from 'antd';
import Button from 'atoms/Button';
import styled from '@emotion/styled';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID
} from 'redux/selectors';
import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { DEVICE } from 'common/Device';
import { CSVLink } from "react-csv";
import AddRole from "organisms/AddRole";
import EditRole from 'organisms/EditRole';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { reduce } from 'lodash';
import Confirm from '../../src/assets/Confirm.svg';
import NotFound from 'organisms/NotFound';

const StyledTable = styled(Table)`
  padding-top: 20px;
  table{
     table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    /* padding: 10px; */
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
`;

const StyledButton = styled(Button)`
    width: 125px;
    color: white;
    background-color: #B7B7B7;
    border-radius: 5px; 
    margin-left: 5%;
`;

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 15px;
    padding-top: 3%;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Roles({
  userRoleId,
  selectedDepartmentId,
}) {
  const [addRole, toggleAddRole] = useState(false);
  const [editRole, toggleEditRole] = useState(false);
  const [RoleDetails, setRoleDetails] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [roleCSVlist, setroleCSVlist] = useState([]);
  const csvLinkEl = useRef();
  const [showLoader, toggleLoader] = useState(false);
  const [ locateID, setLocateID] = useState();

  const CSVHeaders = [
    { label: "Role ID", key: "role_id" },
    { label: "Role name", key: "roleName" },
    { label: "No of employee", key: "NoOfEmpolyee" },
    { label: "Description", key: "roleDescription" },
  ];

  const columns = [
    {
      title: 'Role ID',
      dataIndex: 'role_id',
      key: 'role_id',
      width: '10%',
      // align: 'center',
    },
    {
      title: 'Role name',
      dataIndex: 'role_name',
      key: 'role_name',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'No of employee',
      dataIndex: 'no_of_employee',
      key: 'no_of_employee',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'role_description',
      key: 'role_description',
      width: '20%',
      // align: 'center',
      className: 'normal-column',
    },
    {
      // title: 'Action',
      dataIndex: '',
      key: '',
      width: '5%',
      align: 'center',
      render: (_, record) => (
        <Dropdown overlay={<StyledViewMenu>
          <Menu>
            <Menu.Item onClick={() => ViewLocation(record)}>
              Edit
            </Menu.Item>
          </Menu>
        </StyledViewMenu>} trigger={['click']} placement="bottomRight">
          <MoreVertIcon style={{cursor:'pointer'}} />
        </Dropdown>
      )
    }
  ];

  const addLocation = (leaveObject, isViewOnly = false) => {
    toggleAddRole(true);
  }

  useEffect(() => {
    getRoleList();
  }, [setRoleList]);

  const getRoleList = async () => {
    setDisplayLocationLoader(true);
    await CALL_API('admin-roles', 'get', {
    }).then(function (response) {
      setRoleList(response.reverse());
      setDisplayLocationLoader(false);
    }).catch(function (error) {
      console.log("[getRoleList], Error-->", error);
    })
  }

  const roleFilter = roleList.filter((val) => {
    if (val === "") {
      return val;
    }
    else if(JSON.stringify(val.status_id).toLowerCase().includes(("1").toLowerCase())){
      return val;
    }
  }) 

  const prepareCSV = async () => {
    if (roleList.length > 0) {
      setroleCSVlist(
        reduce(roleList, function (result, list) {
          result.push({
            "Role ID": list.role_id,
            "Role name": list.role_name,
            "No of Employee": list.no_of_employee,
            "Description": list.role_description,
          });
          return result;
        }, [])
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const ViewLocation = (LocationObject, isViewOnly = false) => {
    toggleEditRole(true);
    setRoleDetails({
      ...LocationObject,
      isViewOnly
    });
  }
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      {userRoleId == 5 && (
        <Row className='page-title'>
          <Col span={12} style={{ fontSize: "20px", fontWeight: "bold" }}>List of all Roles</Col>
          <Col span={12} className="align-right">
            <Button type="link" style={{ border: "1px solid", marginBottom:"2%" }} onClick={prepareCSV}>
              Export CSV file
            </Button>
            <CSVLink
              header={CSVHeaders}
              data={roleCSVlist}
              filename={"role-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            <StyledButton type="primary" htmlType='submit' onClick={() => addLocation(true)} style={{ cursor: "pointer" }}>+Add Role</StyledButton>
          </Col>
        </Row>)}

      {userRoleId == 5 && (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            dataSource={roleFilter}
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
            rowKey='role_id'
            pagination={roleFilter.length > 20 ? paginationOptions : false}
          />
        </Spin>)}
      {userRoleId != 5 && <NotFound/>}   
      {userRoleId == 5 && (
        <AddRole
          isModalVisible={addRole}
          closeModal={() => toggleAddRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
        />)}

      {userRoleId == 5 && (
        <EditRole
          isModalVisible={editRole}
          closeModal={() => toggleEditRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
        />)}
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Roles);