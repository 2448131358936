import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, message } from 'antd';
import { Pie } from '@ant-design/plots';
import Button from 'atoms/Button';
import moment from 'moment';
import styled from '@emotion/styled';
import { CSVLink } from "react-csv";

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getAllDepartmentList,
  getLeaveCategoryList,
  getShiftTimeMaster,
} from 'redux/selectors';
import { bindActionCreators } from 'redux';
import {
  toggleProcessingModal
} from 'redux/actions';

import { CALL_API } from 'common/API';
import { MONTH_LIST, REPORT_DURATION_TYPES, STATUS_CODE } from 'common/Constants';

import { addDaysinDate, dateInDetail, getKenyanDateTime, getMonthDateRange, getMySqlDate, momentDate, addMonthinDate } from 'utils/Date';
import COLORS from 'common/Colors';
import { includes, reduce, concat, find, get, filter } from 'lodash';
import ViewMore from 'organisms/ViewMore';

const StyledTable = styled(Table)`
padding-top: 20px;
.ant-table-content {
  overflow-x: auto;
  max-height:500px;
  white-space: nowrap;
}
table {
  width: 100% !important;
}
.ant-table-cell {
  padding: 20px;
}
.ant-table-thead .ant-table-cell {
  font-weight: bold;
  color: ${COLORS.SECONDARY_BLACK}
}
.ant-table-cell:nth-last-child(2) {
  min-width: 200px;
  white-space: normal;
}
.non-white {
  background: #f9f9f9;
}
`;

const StyledSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom :10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color:  ${COLORS.PRIMARY};
  width: initial;
  background:transparent;
`;

const StyledChartCol = styled(Col)`
width:100%;
padding: 40px 10px 10px 10px;
`

function LeaveReport({
  userRoleId,
  leaveCategoryList,
  allDepartmentList,
  selectedDepartmentId
}) {
  const [leaveList, setLeaveList] = useState([]);
  const [staffLeaveList, setStaffLeaveList] = useState([]);
  const [staffName, setStaffName] = useState(' ');
  const [CSVLeaveList, setCSVLeaveList] = useState([]);
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [approvedLeaves, setApprovedLeaves] = useState(0);
  const [pendingLeaves, setPendingLeaves] = useState(0);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [filterList, setFilterList] = useState([]);
  const [expandedReasons, setExpandedReasons] = useState('')

  const STAFF_LIST_ALL = leaveList.map((val) => {
    return { value: val.user_id, label: val.name }
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));
  const ALL = [{ value: "*", label: "All Employees" }]
  const CSVHeaders = [
    { label: "Employee ID", key: "employee_id" },
    { label: "Name", key: "name" },
    { label: "Department", key: "job_title_name" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Days", key: "days" },
    { label: "Leave Type", key: "leave_category_name" },
    { label: "Reason / Reason of decline", key: "reason" },
    { label: "Status", key: "status" },
  ];

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      width: '8%',
      // align: 'center'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      // align: 'center'
    },
    {
      title: 'Department',
      dataIndex: 'job_title_name',
      key: 'job_title_name',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '7%',
      // align: 'center',
      sorter: (a, b) => (moment(a.start_date).format("DD"), moment(a.start_date).format("MM"), moment(a.start_date).format("YYYY") - moment(b.start_date).format("DD"), moment(b.start_date).format("MM"), moment(b.start_date).format("YYYY")),
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: 'Start Day',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '7%',
      // align: 'center',
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '7%',
      // align: 'center',
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'End Day',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      // align: 'center',
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'Days',
      dataIndex: 'number_of_days',
      key: 'number_of_days',
      width: '7%',
      // align: 'center'
    },
    {
      title: 'Leave Type',
      dataIndex: 'leave_category_name',
      key: 'leave_category_name',
      width: '10%',
      // align: 'center'
      render: (_, record) => {
        if (record.new_leave_category_name === null) {
          return record.leave_category_name;
        } else {
          return record.new_leave_category_name;
        }
      }
    },

    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: '15%',
      // align: 'center',

      render: (date, record) => {
        return (
          <>
           <ViewMore value={`${record.reason}${record.decline_reason}`} />
          </>
        );
      }

    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      // width: '7%',
      align: 'center'
    },
  ];


  // if (userRoleId === 1) {
  //   columns.splice(2, 0, {
  //     title: 'Department',
  //     dataIndex: 'job_title_name',
  //     key: 'job_title_name',
  //     width: '10%',
  //     align: 'center'
  //   })
  // }

  const data = [
    {
      type: 'Approved',
      value: approvedLeaves,
    },
    {
      type: 'Pending',
      value: pendingLeaves,
    }
  ];
  const config = {
    width: 150,
    height: 150,
    appendPadding: 6,
    data,
    angleField: 'value',
    colorField: 'type',
    color: [COLORS.CHART.AVAILABLE, COLORS.CHART.CONSUMED],
    radius: 1,
    innerRadius: 0.8,
    legend: {
      // layout: 'horizontal',
      position: 'top'
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '14px'
        },
        content: `${approvedLeaves}/${pendingLeaves}`,
      },
    },
  };

  useEffect(() => {
    getLeaveList();
  }, [selectedDepartmentId, userRoleId, form.getFieldValue('duration_type'), form.getFieldValue('start_date'), form.getFieldValue('end_date'), form.getFieldValue('month'), form.getFieldValue('department'), form.getFieldValue('leave_type')]);

  const getLeaveList = async () => {
    setLeaveList([]);
    const {
      start_date,
      end_date,
      duration_type,
      month,
      department,
      leave_type,
    } = form.getFieldsValue();
    form.setFieldsValue({ 'staff_name': "All Employees" });
    setDisplayTableLoader(true);
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), 'index', 0)
    );
    const {
      code,
      teamLeaves = []
    } = await CALL_API('leave-report', 'post', {
      department: (userRoleId === 1 || userRoleId === 6) ? [department] : (selectedDepartmentId),
      leave_type,
      status_id: '4,5',
      start_date: getMySqlDate(duration_type === 2 ? startDateOfMonth : start_date),
      end_date: getMySqlDate(duration_type === 2 ? lastDateOfMonth : end_date),
    });
    setDisplayTableLoader(false);
    console.log(teamLeaves);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      let recordList = []
      teamLeaves.map(list => {
        Object.entries(list).forEach(([key, value]) => {
          recordList = concat(recordList, value)
        })
      })
      setLeaveList(recordList.reverse());
      setStaffLeaveList(recordList.reverse())
      setPendingLeaves(filter(recordList, { status_id: 4 }).length);
      setApprovedLeaves(filter(recordList, { status_id: 5 }).length);
    }
  }

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }

  const prepareCSV = async (event, done) => {
    if (leaveList.length > 0) {
      setCSVLeaveList(
        reduce(leaveList, function (result, leave) {

          const { date_th: end_day, shortMonthName: end_shortMonthName, year: end_year, longDay: end_longDay } = dateInDetail(leave.end_date);
          const { date_th: start_day, shortMonthName: start_shortMonthName, year: start_year, longDay: start_longDay } = dateInDetail(leave.start_date);
          result.push({
            "Employee ID": leave.employee_id,
            "Name": leave.name,
            "Department": leave.job_title_name,
            "Start Date": `${start_day} ${start_shortMonthName} ${start_year}`,
            "Start Day": `${moment(leave.start_date).format("dddd")}`,
            "End Date": `${end_day} ${end_shortMonthName} ${end_year}`,
            "End Day": `${moment(leave.end_date).format("dddd")}`,
            "Days": leave.number_of_days,
            "Leave Type": leave?.new_leave_category_name === null || leave?.new_leave_category_name === "" ? leave?.leave_category_name : leave?.new_leave_category_name,
            "Reason / Reason of decline": leave.reason || leave.decline_reason,
            "Status": leave.status_name === 'Approved' ? "Leave" : leave.status_name,
          });
          return result;
        }, [])
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const handleChange = (event) => {
    setStaffName(event);
    if (event == "*") {
      getLeaveList();
    }
    const filteredStaffList = staffLeaveList.filter((val) => val.user_id === event);
    setLeaveList(filteredStaffList);
    setStaffLeaveList(staffLeaveList);
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col xs={24} sm={19} md={19} className='page-title-head'>
          <Space>
            <StyledSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              initialValues={{
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(),)),
                department: '',
                leave_type: '',
              }}
              onFinish={getLeaveList}
            >
              <Form.Item
                name='duration_type'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder='Select...'
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>

              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Form.Item name='start_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='Start date'
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name='end_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='End date'
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item
                        name='month'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select...'
                          options={MONTH_LIST}
                        />
                      </Form.Item>
                    )
                  }
                }
              </Form.Item>

              {
                (userRoleId === 1 || userRoleId === 6) && (
                  <Form.Item
                    name='department'
                  >
                    <Select
                      placeholder="Select Department"
                      options={concat({
                        department_id: '',
                        department_name: 'All Departments'
                      }, allDepartmentList)}
                      fieldNames={{
                        label: 'department_name',
                        value: 'department_id'
                      }}
                    />
                  </Form.Item>
                )
              }

              <Form.Item
                name='leave_type'
              >
                <Select
                  showSearch
                  placeholder='Search leave type'
                  name='leave_type'
                  optionFilterProp='leave_category_name'
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) => {
                    optionA.leave_category_name.toLowerCase().localeCompare(optionB.leave_category_name.toLowerCase())
                  }}
                  options={concat({
                    leave_category_id: '',
                    leave_category_name: 'All Categories'
                  }, leaveCategoryList)}
                  fieldNames={{
                    label: 'leave_category_name',
                    value: 'leave_category_id'
                  }}
                />
              </Form.Item>
              <Form.Item
                name='staff_name'
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(ALL, STAFF_LIST)}
                  value={staffName}
                  onChange={handleChange}
                />
              </Form.Item>
              {/* <Button type="primary" htmlType='submit'>Search</Button> */}
            </StyledSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={prepareCSV} disabled={leaveList.length === 0}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVLeaveList}
            filename={"leave-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      {
        approvedLeaves > 0 && pendingLeaves > 0 && (
          <Row>
            <StyledChartCol>
              <Pie {...config} />
            </StyledChartCol>
          </Row>
        )
      }
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={leaveList}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='user_leave_id'
          pagination={leaveList.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  allDepartmentList: getAllDepartmentList(state),
  leaveCategoryList: getLeaveCategoryList(state),
  shiftTimeMaster: getShiftTimeMaster(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateProcessingModal: toggleProcessingModal,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(LeaveReport);
