import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeesPerRole({ report }) {
 
  const config = {
    padding: 'auto',
    data: report,
    height: 300,
    xField: 'role_name',
    yField: 'employee',
    seriesField: 'role_name',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      max: isNaN(max(map(report, 'employee'))) ? 100 : max(map(report, 'employee')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    theme: {
      colors10: [
        // '#0F1C73',
        // '#1A449F',
        // '#2B67BA',
        '#3C88C0',
        '#4BA7B3',
        '#5AC39B',
        '#6ADD7F',
        '#A8DD6A',
        '#DDDD6A',
      ],
    },
  };
  return (
    <>      
      <StyledTitle>
          Number Of Employees Per Role
      </StyledTitle>
      <StyledChartContainer>
          <Column
            {...config}
             />
      </StyledChartContainer>
    </>
  );
}


export default EmployeesPerRole