import React from 'react';
import { Modal, Button } from 'antd';
import styled from '@emotion/styled';
import { DEVICE } from 'common/Device';

const StyledModal = styled(Modal)`
display:none;
  position: absolute;
  right: 30px;
  top: 30px;
  .ant-modal-body {
    text-align: center;
    padding-bottom: 10px;
  }
  .ant-modal-footer {
    display: none;
  }

`;

const StyledTryAgainButton = styled(Button)`
  border: none;
  padding: 0px;
  height: 0px;
`;

function CheckInReminderModal({
  isModalVisible,
  checkIn,
  closeModal
}) {

  return (
    <StyledModal
      title=""
      visible={isModalVisible}
      onCancel={closeModal}
      width={300}
      footer={[]}
    >
      <h3 style={{ marginBottom: '20px' }}>Reminder</h3>
      <h5>You have exceeded the check in time. Do you want to <StyledTryAgainButton type="link" onClick={checkIn}>check in</StyledTryAgainButton>?</h5>
    </StyledModal>
  );
}

export default CheckInReminderModal;
