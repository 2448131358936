import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Input, InputNumber } from 'antd';

// Redux
import styled from '@emotion/styled';
import { CALL_API } from 'common/API';
import { bindActionCreators } from 'redux';
import {
  toggleProcessingModal, toggleSuccessModal,  setLocationList
} from 'redux/actions';
import { connect } from 'react-redux';
import Button from 'atoms/Button';
import { getLocations } from 'redux/selectors';
import { NON_EMPTY_REGEX, NON_CHARACTER } from 'common/Constants';
import { STATUS_CODE } from 'common/Constants';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
    text-align: center;
    font-size: 20px;
    padding-top: 3%;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker, .ant-input-number {
    width: 100%;
  }
  .ant-input{
    border-radius: 5px;
  }
`;

const StyledFooterDiv = styled.div`
  text-align: center;
`;

function EditLocationModal({
  isModalVisible,
  closeModal,
  getLocationList,
  LocationDetails,
  saveLocationList
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    const {
      location_id = '',
      LocationName = '',
      latitude = '',
      longitude = '',
      LocationDescription = '',
      isViewOnly
    } = LocationDetails;
    setIsViewOnly(isViewOnly);
    form.setFieldsValue({
      "locationID": location_id,
      "Location_Name": LocationName,
      "location_latitude": latitude,
      "location_longitude": longitude,
      "description": LocationDescription,
    });
  }, [LocationDetails]);

  const fetchMasterData = async (fetch_master_data) => {
    const {
      code,
      locations,
    } = await CALL_API('master-data', 'post', {
      fetch_master_data
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveLocationList(locations)
      }
    }
   }


  const onFinish = async (values) => {
    toggleLoader(true);
    const { locationID, Location_Name, location_latitude, location_longitude, description } = values;
    await CALL_API('admin-location', 'patch', {
      "location_id": locationID,
      "location_name": Location_Name,
      "latitude": location_latitude,
      "longitude": location_longitude,
      "location_description": description,
    });
    fetchMasterData(true)
    toggleLoader(false);
    closeModal(true);
    getLocationList();
  }
  return (
    <>
      <StyledModal
        title='Edit Location'
        visible={isModalVisible}
        onCancel={() => closeModal(false)}
        width={500}
        footer={[]}
      >
        <Form
          form={form}
          name='EditLocation'
          layout='vertical'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label='Location ID'
                name='locationID'
              >
                <Input disabled/>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label='Location Name'
                name='Location_Name'
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Please enter location name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            { LocationDetails?.latitude && LocationDetails?.longitude && (<>
            <Col xs={12} sm={12} md={12} xl={12}>
              <Form.Item
                label='Latitude'
                name='location_latitude'
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: 'Please enter latitude!',
                  },
                ]}
              >
                <Input placeholder='Enter latitude' disabled={isViewOnly}/>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} xl={12}>
              <Form.Item
                label='Longitude'
                name='location_longitude'
                rules={[
                  {
                    required: true,
                    pattern: NON_CHARACTER,
                    message: 'Please enter longitude!',
                  },
                ]}
              >
                <Input placeholder='Enter longitude' disabled={isViewOnly}/>
              </Form.Item>
            </Col>
            </>)}
          </Row>
          <Form.Item
            label='Description'
            name='description'
            rules={[
              {
                required: true,
                pattern: NON_EMPTY_REGEX,
                message: 'Please enter location description!',
              },
            ]}
          >
            <TextArea  placeholder='Type something..' maxLength={500} autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <StyledFooterDiv>
            <Button loading={showLoader} type="primary" htmlType='submit'>{showLoader ? 'Update' : 'Update'}</Button>
          </StyledFooterDiv>
        </Form>
      </StyledModal>
    </>
  );
}

const mapStateToProps = state => ({
  locationList: getLocations(state)
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateProcessingModal: toggleProcessingModal,
    updateSuccessModal: toggleSuccessModal,
    saveLocationList: setLocationList,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps, null)(EditLocationModal);




