import axios from 'axios';
// import FormData from 'form-data'
// import _each from 'lodash/each';

import { SERVICE_URL, STATUS_CODE } from 'common/Constants';

export const CALL_API = async (action, method, payload) => {
  // const data = getParams(payload);
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
        'Tymeplusjwt': window.localStorage.getItem('Tymeplusjwt') || '',
        // 'Cache-Control': 'no-cache',
      }
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else throw {};
  } catch (err) {
    console.log(err)
    return err;
  };
};

export const CALL_API_WITH_HEADER = async (action, method, payload, additionalHeaders) => {
  // const data = getParams(payload);
  try {
    const resp = await axios({
      method,
      url: `${SERVICE_URL}${action}`,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
        'Tymeplusjwt': window.localStorage.getItem('Tymeplusjwt') || '',
        ...additionalHeaders
      }
    });
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else throw {};
  } catch (err) {
    console.log(err)
    return err;
  };
};

export const UPLOAD_DOCUMENT = async (action, file) => {
  try {
    let data = new FormData();
    data.append('file', file);

    // let data = new FormData();
    // data.append('file', file);

    const resp = await axios.post(
      `${SERVICE_URL}${action}`,
      data,
      {
        'Tymeplusjwt': window.localStorage.getItem('Tymeplusjwt') || '',
        'Content-Type': 'multipart/form-data',
      }
    );
    const { status, response } = resp.data;
    if (status === STATUS_CODE.SUCCESS) return response;
    else throw {};
  } catch (err) {
    return err;
  }
}

// const getParams = (payload) => {
//   const formData = new FormData();
//   _each(payload, (value, key) => {
//     formData.append(key, value);
//   });
//   return formData;
// };
