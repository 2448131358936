/* 
  filename: Dropzone.js 
*/

import COLORS from "common/Colors";
import React from "react";
import { UploadOutlined } from '@ant-design/icons';
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const StyledDropzone = styled.div`
  text-align: center;
  padding: 20px;
  width: 100%;
  margin: auto;
  height: 300px;
  background: #e3e3e3;
  border-radius: 5px;
  .anticon {
    padding-top: 5rem;
  }
  .message {
    font-size: 1rem;
    padding-top: 1rem;
    color: ${COLORS.GREY};
  }
  .upload-icon {
    font-size: 3rem;
    color: ${COLORS.GREY};
  }
`;

const Dropzone = ({ onDrop, accept, fileName }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */
  return (
    <StyledDropzone className="dropzone-div" {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            <UploadOutlined className="upload-icon"/>
            <div className="message">
              {
                navigator.userAgent !== 'application' ? 'Drag and drop documents here' : 'Upload documents'
              }
            </div>
            <div>{fileName}</div>
          </p>
        )}
      </div>
    </StyledDropzone>
  );
};

export default Dropzone;