import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Spin, Select, Button } from "antd";
import styled from "@emotion/styled";
import LeaveDonutChart from "./LeaveDonutChart";
import { LEAVE_ICON, LEAVE_INFO } from "common/Constants";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { CSVLink } from "react-csv";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getLeaveCategoryList,
  getUserList,
} from "redux/selectors";
import { find, get, includes, map, reduce, concat, uniqBy } from "lodash";
import DepartmentStaffLeaveBalance from "./DepartmentStaffLeaveBalance";
import { dateInDetail, getKenyanDateTime } from "utils/Date";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  float: right;
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const StyledDepartmentTitle = styled.div`
  padding-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function DepartmentLeaveBalance({
  displayModal,
  toggleModal,
  title,
  leaveTypeId,
  allDepartmentList,
  leaveCategoryList,
  userList,
}) {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [staffDepartmentLeaveModal, toggleStaffDepartmentLeaveModal] =
    useState(false);
  const [leaveName, setLeaveName] = useState("");
  const [departmentLeaves, setDepartmentLeaves] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState("");
  const leaveDataArray = [
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: get(COLORS, `LEAVE.${leaveTypeId}.AVAILABLE`, ""),
    },
    {
      type: LEAVE_INFO.CONSUMED,
      value: 0,
      color: get(COLORS, `LEAVE.${leaveTypeId}.CONSUMED`, ""),
    },
  ];
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);
  const [APIResponse, setAPIResponse] = useState([]);
  const [xportData, setXportData] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  const openDetailWindow = (departmentId) => {
    setSelectedDepartmentId(departmentId);
    toggleStaffDepartmentLeaveModal(true);
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];

  useEffect(() => {
    if (displayModal) {
      setLeaveName(
        get(
          find(leaveCategoryList, { leave_category_id: leaveTypeId }),
          "leave_category_name",
          ""
        )
      );
      const departmentList = [];
      map(allDepartmentList, ({ department_id, department_name }) => {
        departmentList.push({
          department_id,
          department_name,
          leaveData: leaveDataArray,
        });
      });
      setDepartmentLeaves(departmentList);
      getReportData();
      setAPIResponse({
        available: [],
        consumed: [],
      });
    }
  }, [displayModal, filteredDepartments]);

  useEffect(() => {
    const data = reduce(
      allDepartmentList,
      (result, department) => {
        if (
          filteredDepartments.length === 0 ||
          filteredDepartments === department.department_id
        ) {
          let dupeleaveDataArray = JSON.parse(JSON.stringify(leaveDataArray));
          const total_leaves =
            get(
              find(APIResponse.available, {
                department_id: department.department_id,
              }),
              "total_leaves",
              0
            ) +
            get(
              find(APIResponse.lastYearConsumedLeaveBalance, {
                department_id: department.department_id,
              }),
              "total_leaves",
              0
            );
          const leaves_consumed = get(
            find(APIResponse.consumed, {
              department_id: department.department_id,
            }),
            "leaves_consumed",
            0
          );
          dupeleaveDataArray[0].value = total_leaves - leaves_consumed;
          dupeleaveDataArray[1].value = leaves_consumed;
          result.push({
            department_id: department.department_id,
            department_name: department.department_name,
            leaveData: dupeleaveDataArray,
          });
        }
        return result;
      },
      []
    );
    setDepartmentLeaves(data);
  }, [filteredDepartments, APIResponse]);

  const getReportData = async () => {
    toggleLeaveReportLoader(true);
    const {
      available,
      consumed,
      lastYearConsumedLeaveBalance,
      allDepartmentUsers,
    } = await CALL_API("leave-service", "post", {
      method: "departmentWiseOverallTotalLeavesAvailableAndConsumed",
      parameters: {
        leave_type: leaveTypeId,
        year: dateInDetail(getKenyanDateTime()).year,
      },
    });
    const allValuesArray = Object.values(allDepartmentUsers).reduce(
      (result, item) => {
        result.push(...item.available, ...item.consumed);
        return result;
      },
      []
    );
    const users = [];
    map(userList, ({ user_id, name, department_id }) => {
      users.push({
        user_id,
        name,
        leaveData: allValuesArray?.filter((item) => item.user_id == user_id),
        department_id,
      });
    });

    setXportData(uniqBy(users, "user_id"));
    setAPIResponse({
      available,
      consumed,
      lastYearConsumedLeaveBalance,
      allDepartmentUsers,
    });

    toggleLeaveReportLoader(false);
  };

  const prepareCSV = async (event, done) => {
    if (xportData.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          xportData,
          function (result, leave) {
            result.push({
              "Employee ID": leave.user_id,
              Name: leave.name,
              "Available Leaves":
                (leave.leaveData[0]?.total_leaves > 0
                  ? leave.leaveData[0]?.total_leaves
                  : 0) -
                (leave.leaveData[1]?.leaves_consumed > 0
                  ? leave.leaveData[1]?.leaves_consumed
                  : 0) + Number((leave.leaveData[0]?.previousYearLeaveBalance && leave.leaveData[0]?.previousYearLeaveBalance < 0
                    ? leave.leaveData[0]?.previousYearLeaveBalance
                    : 0)),
              "Consumed Leaves": leave.leaveData[1]?.leaves_consumed
                ? leave.leaveData[1]?.leaves_consumed
                : 0,
              "Total Leaves": leave.leaveData[0]?.total_leaves
                ? leave.leaveData[0]?.total_leaves
                : 0,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
    }
  };
  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={() => {
          setFilteredDepartments("");
          toggleModal();
        }}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={24}>
              <StyledSelect
                // mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search Departments"
                name="department"
                optionFilterProp="department_name"
                filterOption={(input, option) =>
                  option.department_name
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.department_name
                    .toLowerCase()
                    .localeCompare(optionB.department_name.toLowerCase());
                }}
                // options={allDepartmentList}
                options={concat(
                  {
                    department_id: "",
                    department_name: "All Departments",
                  },
                  allDepartmentList
                )}
                fieldNames={{
                  label: "department_name",
                  value: "department_id",
                }}
                value={filteredDepartments}
                onChange={(newValue) => setFilteredDepartments(newValue)}
              />
            </Col>
          </Row>
          <Row className="justify-content-flex-end">
            <StyledButton3
              onClick={prepareCSV}
              disabled={xportData.length === 0}
            >
              Export CSV file
            </StyledButton3>
            <CSVLink
              header={CSVHeaders}
              data={CSVDepartmentLeave}
              filename={"Leave List.csv"}
              asyncOnClick={true}
              ref={csvLinkDepartment}
            />
          </Row>
          <Row gutter={16}>
            {map(
              departmentLeaves,
              ({ department_id, department_name, leaveData }) => (
                <StyledCol
                  md={12}
                  sm={24}
                  xs={24}
                  key={department_id}
                  onClick={() => openDetailWindow(department_id)}
                >
                  <StyledDepartmentTitle>
                    <b>Department: </b>
                    {department_name}
                  </StyledDepartmentTitle>
                  <LeaveDonutChart
                    iconName={LEAVE_ICON[leaveTypeId]}
                    title={leaveName}
                    data={leaveData}
                  />
                </StyledCol>
              )
            )}
          </Row>
        </Spin>
      </StyledModal>
      <DepartmentStaffLeaveBalance
        displayModal={staffDepartmentLeaveModal}
        toggleModal={() => toggleStaffDepartmentLeaveModal(false)}
        leaveTypeId={leaveTypeId}
        selectedDepartmentId={[selectedDepartmentId]}
        leaveName={leaveName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(DepartmentLeaveBalance);
