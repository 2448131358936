import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function YearwiseManagerToStaffRatio({}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const [ reportData, setReportData ] = useState([
    {
        year:2016,
        ratio: 10.6
    },
    {
        year:2017,
        ratio: 20.8
    },
    {
        year:2018,
        ratio: 10.6
    },
    {
        year:2019,
        ratio: 33.5
    },
    {
        year:2020,
        ratio: 33.5
    },
    {
        year:2021,
        ratio: 33.5
    },
    {
        year:2022,
        ratio: 33.5
    },
  ]);
  const config = {
    data: reportData,
    height:300,
    xField: 'year',
    yField: 'ratio',
    // seriesField: 'year',
    label: {
      position: 'middle',
      content:''
    },
    columnStyle: {
      cursor: 'pointer'
    },
    legend: false,
    yAxis: {
      max: isNaN(max(map(reportData, 'ratio'))) ? 100 : max(map(reportData, 'ratio')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    // theme: {
    //   colors10: [
    //     // '#0F1C73',
    //     // '#1A449F',
    //     '#2B67BA',
    //     '#3C88C0',
    //     '#4BA7B3',
    //     '#5AC39B',
    //     '#6ADD7F',
    //     '#A8DD6A',
    //     '#DDDD6A',
    //   ],
    // },
  };
  return (
    <>      
      <StyledTitle>
          Span Of Control : Manager To Staff Ratio
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Column
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}


export default YearwiseManagerToStaffRatio