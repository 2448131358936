import React from "react";
import { Row, Col, Card, Modal, Avatar, Spin, TimePicker } from "antd";
import { useState, useEffect } from "react";
import COLORS from "common/Colors";
import styled from "styled-components";
// Redux
import { connect } from "react-redux";
import {
  getUserInfo,
  getNotificationList,
  getUserRoleId,
  getProfilePic,
} from "redux/selectors";
import { get, startCase } from "lodash";
import { dateInDetail } from "utils/Date";
import { DEVICE } from "common/Device";
import ChangePasswordModal from "organisms/ChangePasswordModal";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { clearStore, setProfilePic } from "redux/actions";
import { Switch } from "antd";
import { CALL_API } from "common/API";
import moment from "moment";

const StyledProfileContainer = styled.div`
  .right {
    display: flex;
    justify-content: end;
    gap: 20px;
    width: 100%;
    background: #e6e6e6;
    padding: 15px;
    border-radius: 5px;
  }
  @media ${DEVICE.tablet} {
    .second_half_info {
      margin-top: 15px;
    }
  }
`;
const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 100px;
  width: 100px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  .main_row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 912px) {
    .second_half_info {
      margin-top: -25px;
    }
  }
`;

const StyledCard1 = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 10px;
  .main_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    .mb-2 {
      font-size: 18px;
      padding-left: 0px !important;
    }
  }
`;
const StyleRow = styled(Row)`
  padding: 5px;
  .sub_title {
    font-weight: bold;
  }
`;

const ResetPasswordLink = styled.div`
  cursor: pointer;
  color: #5c86e6;
  font-weight: bold;
`;

function MyProfile({
  userInfo,
  removeStoreData,
  userRoleId,
  profilePic,
}) {
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const joiningDate = dateInDetail(get(userInfo, "joining_date", ""));
  let history = useHistory();
  const [attendanceReport, setAttendanceReport] = useState(false);
  const [overtimeReport, setOvertimeReport] = useState(false);
  const [earlyCheckoutReport, setEarlyCheckoutReport] = useState(false);
  const [lateCheckinReport, setLateCheckinReport] = useState(false);
  const [absentReport, setAbsentReport] = useState(false);
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [attendanceReportId, setAttendanceReportId] = useState(0);
  const [overtimeReportId, setOvertimeReportId] = useState(0);
  const [earlyCheckoutReportId, setEarlyCheckoutReportId] = useState(0);
  const [lateCheckinReportId, setLateCheckinReportId] = useState(0);
  const [absentReportId, setAbsentReportId] = useState(0);
  const [attendanceReportTime, setAttendanceReportTime] = useState();
  const [overtimeReportTime, setOvertimeReportTime] = useState();
  const [earlyCheckoutReportTime, setEarlyCheckoutReportTime] = useState();
  const [lateCheckinReportTime, setLateCheckinReportTime] = useState();
  const [absentReportTime, setAbsentReportTime] = useState();

  const logoutUser = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to logout?",
      okText: "Logout",
      cancelText: "Cancel",
      onOk: () => {
        setTimeout(() => {
          window.localStorage.removeItem("Tymeplusjwt");
          window.localStorage.removeItem("login_reminder");
          removeStoreData();
          history.push(`/`);
        }, 500);
      },
    });
  };

  const getPreferenceData = async () => {
    toggleReportLoader(true);
    const response = await CALL_API("user-preference-report", "get");
    response.map((val) => {
      if (val?.report_id == 1) {
        setAttendanceReport(val?.status_name == "true" ? true : false);
        setAttendanceReportId(val?.user_preference_id);
        setAttendanceReportTime(moment(val?.user_preference_time, "HH:mm"));
      } else if (val?.report_id == 2) {
        setOvertimeReport(val?.status_name == "true" ? true : false);
        setOvertimeReportId(val?.user_preference_id);
        setOvertimeReportTime(moment(val?.user_preference_time, "HH:mm"));
      } else if (val?.report_id == 3) {
        setLateCheckinReport(val?.status_name == "true" ? true : false);
        setLateCheckinReportId(val?.user_preference_id);
        setLateCheckinReportTime(moment(val?.user_preference_time, "HH:mm"));
      } else if (val?.report_id == 4) {
        setEarlyCheckoutReport(val?.status_name == "true" ? true : false);
        setEarlyCheckoutReportId(val?.user_preference_id);
        setEarlyCheckoutReportTime(moment(val?.user_preference_time, "HH:mm"));
      } else if (val?.report_id == 5) {
        setAbsentReport(val?.status_name == "true" ? true : false);
        setAbsentReportId(val?.user_preference_id);
        setAbsentReportTime(moment(val?.user_preference_time, "HH:mm"));
      }
    });

    toggleReportLoader(false);
  };

  const handleChange = async (id, val, time) => {
    await CALL_API("user-preference-report", "patch", {
      user_preference_id: id,
      status_name: val,
      user_preference_time: time,
    });

    getPreferenceData();
  };

  useEffect(() => {
    getPreferenceData();
  }, [userRoleId]);

  return (
    <>
      <StyledProfileContainer>
        <Row className="profile-row" gutter={24}>
          <Col md={24} sm={24} xs={24} lg={24}>
            <StyledCard>
              <Row className="main_row">
                <Col md={24} sm={24} xs={24} lg={4}>
                  <StyledStaffNameContainer>
                    {profilePic === 'null' || profilePic === undefined ||  profilePic === null? (
                      <>
                        <StyledAvatar size="default">
                          {startCase(get(userInfo, "name", "").charAt(0))}
                        </StyledAvatar>
                      </>
                    ) : (
                      <>
                        <StyledAvatar
                          src={profilePic}
                          size="default"
                        ></StyledAvatar>
                      </>
                    )}
                  </StyledStaffNameContainer>
                </Col>
                <Col md={24} sm={24} xs={24} lg={10}>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Name</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "name", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Username</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "username", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Email ID</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "email_id", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Joining date</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {joiningDate.date_th} {joiningDate.monthName}{" "}
                      {joiningDate.year}
                    </Col>
                  </StyledRow>
                </Col>
                <Col
                  md={24}
                  sm={24}
                  xs={24}
                  lg={10}
                  className="second_half_info"
                >
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Designation</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "job_title", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow>
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Department</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "department", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Payroll Number</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "payroll", "")}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>ID Number</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {get(userInfo, "national_Id", "")}
                    </Col>
                  </StyledRow>
                </Col>
                <div className="right">
                  <Col>
                    <ResetPasswordLink
                      onClick={() => setOpenResetPasswordModal(true)}
                    >
                      Change Password
                    </ResetPasswordLink>
                  </Col>
                  <Col>
                    <b>
                      <ResetPasswordLink onClick={() => logoutUser()}>
                        Logout
                      </ResetPasswordLink>
                    </b>
                  </Col>
                </div>
              </Row>
            </StyledCard>
          </Col>

          {userRoleId !== 4 && (
            <>
              <Col md={24} sm={24} xs={24} lg={24}>
                <StyledCard1 bordered={false}>
                  <Spin spinning={displayReportLoader}>
                    <div className="main_div">
                      <Col className=" mt-1 mb-2" lg={24}>
                        <b>Report Preferences</b>
                      </Col>

                      <StyleRow gutter={16}>
                        <Col className="mb-1" xs={12} lg={8}>
                          Attendance Report
                        </Col>
                        <Col className="align-center" xs={12} lg={4}>
                          <Switch
                            size="small"
                            checked={attendanceReport}
                            onClick={() => {
                              handleChange(
                                attendanceReportId,
                                attendanceReport === true ? "false" : "true",
                                attendanceReportTime.format("HH:mm")
                              );
                              setAttendanceReport(
                                attendanceReport === true ? false : true
                              );
                            }}
                          />
                        </Col>
                        <Col className="mb-1" xs={12} lg={6}>
                          Period : Daily{" "}
                        </Col>
                        <Col className="align-center" xs={12} lg={6}>
                          {attendanceReport ? (
                            <TimePicker
                              value={attendanceReportTime}
                              format={"HH:mm"}
                              onChange={(val) => {
                                handleChange(
                                  attendanceReportId,
                                  attendanceReport === true ? "true" : "false",
                                  val.format("HH:mm")
                                );
                                setAttendanceReportTime(moment(val, "HH:mm"));
                              }}
                              clearIcon={null}
                            />
                          ) : (
                            <TimePicker
                              value={attendanceReportTime}
                              format={"HH:mm"}
                              clearIcon={null}
                              disabled
                            />
                          )}
                        </Col>
                      </StyleRow>

                      <StyleRow gutter={16}>
                        <Col className="mb-1" xs={12} lg={8}>
                          Overtime Report
                        </Col>
                        <Col className="align-center" xs={12} lg={4}>
                          <Switch
                            size="small"
                            checked={overtimeReport}
                            onClick={() => {
                              handleChange(
                                overtimeReportId,
                                overtimeReport === true ? "false" : "true",
                                overtimeReportTime.format("HH:mm")
                              );
                              setOvertimeReport(
                                overtimeReport === true ? false : true
                              );
                            }}
                          />
                        </Col>
                        <Col className="mb-1" xs={12} lg={6}>
                          Period : Daily{" "}
                        </Col>
                        <Col className="align-center" xs={12} lg={6}>
                          {overtimeReport ? (
                            <TimePicker
                              value={overtimeReportTime}
                              format={"HH:mm"}
                              onChange={(val) => {
                                handleChange(
                                  overtimeReportId,
                                  overtimeReport === true ? "true" : "false",
                                  val.format("HH:mm")
                                );
                                setOvertimeReportTime(moment(val, "HH:mm"));
                              }}
                              clearIcon={null}
                            />
                          ) : (
                            <TimePicker
                              value={overtimeReportTime}
                              format={"HH:mm"}
                              clearIcon={null}
                              disabled
                            />
                          )}
                        </Col>
                      </StyleRow>

                      <StyleRow>
                        <Col xs={14} lg={12} className="sub_title">
                          Exception Report :
                        </Col>
                      </StyleRow>

                      <StyleRow gutter={16}>
                        <Col className="mb-1" xs={12} lg={8}>
                          Late Check-in Report
                        </Col>
                        <Col className="align-center" xs={12} lg={4}>
                          <Switch
                            size="small"
                            checked={lateCheckinReport}
                            onClick={() => {
                              handleChange(
                                lateCheckinReportId,
                                lateCheckinReport === true ? "false" : "true",
                                lateCheckinReportTime.format("HH:mm")
                              );
                              setLateCheckinReport(
                                lateCheckinReport === true ? false : true
                              );
                            }}
                          />
                        </Col>
                        <Col className="mb-1" xs={12} lg={6}>
                          Period : Daily{" "}
                        </Col>
                        <Col className="align-center" xs={12} lg={6}>
                          {lateCheckinReportTime ? (
                            <TimePicker
                              value={lateCheckinReportTime}
                              format={"HH:mm"}
                              onChange={(val) => {
                                handleChange(
                                  lateCheckinReportId,
                                  lateCheckinReport === true ? "true" : "false",
                                  val.format("HH:mm")
                                );
                                setLateCheckinReportTime(moment(val, "HH:mm"));
                              }}
                              clearIcon={null}
                            />
                          ) : (
                            <TimePicker
                              value={lateCheckinReportTime}
                              format={"HH:mm"}
                              clearIcon={null}
                              disabled
                            />
                          )}
                        </Col>
                      </StyleRow>

                      <StyleRow gutter={16}>
                        <Col className="mb-1" xs={12} lg={8}>
                          Early Check-out Report
                        </Col>
                        <Col className="align-center" xs={12} lg={4}>
                          <Switch
                            size="small"
                            checked={earlyCheckoutReport}
                            onClick={() => {
                              handleChange(
                                earlyCheckoutReportId,
                                earlyCheckoutReport === true ? "false" : "true",
                                earlyCheckoutReportTime.format("HH:mm")
                              );
                              setEarlyCheckoutReport(
                                earlyCheckoutReport === true ? false : true
                              );
                            }}
                          />
                        </Col>
                        <Col className="mb-1" xs={12} lg={6}>
                          Period : Daily{" "}
                        </Col>
                        <Col className="align-center" xs={12} lg={6}>
                          {earlyCheckoutReportTime ? (
                            <TimePicker
                              value={earlyCheckoutReportTime}
                              format={"HH:mm"}
                              onChange={(val) => {
                                handleChange(
                                  earlyCheckoutReportId,
                                  earlyCheckoutReport === true
                                    ? "true"
                                    : "false",
                                  val.format("HH:mm")
                                );
                                setEarlyCheckoutReportTime(
                                  moment(val, "HH:mm")
                                );
                              }}
                              clearIcon={null}
                            />
                          ) : (
                            <TimePicker
                              value={earlyCheckoutReportTime}
                              format={"HH:mm"}
                              disabled
                              clearIcon={null}
                            />
                          )}
                        </Col>
                      </StyleRow>

                      <StyleRow gutter={16}>
                        <Col className="mb-1" xs={12} lg={8}>
                          Absent Report
                        </Col>
                        <Col className="align-center" xs={12} lg={4}>
                          <Switch
                            size="small"
                            checked={absentReport}
                            onClick={() => {
                              handleChange(
                                absentReportId,
                                absentReport === true ? "false" : "true",
                                absentReportTime.format("HH:mm")
                              );
                              setAbsentReport(
                                absentReport === true ? false : true
                              );
                            }}
                          />
                        </Col>
                        <Col className="mb-1" xs={12} lg={6}>
                          Period : Daily{" "}
                        </Col>
                        <Col className="align-center" xs={12} lg={6}>
                          {absentReportTime ? (
                            <TimePicker
                              value={absentReportTime}
                              format={"HH:mm"}
                              onChange={(val) => {
                                handleChange(
                                  absentReportId,
                                  absentReport === true ? "true" : "false",
                                  val.format("HH:mm")
                                );
                                setAbsentReportTime(moment(val, "HH:mm"));
                              }}
                              clearIcon={null}
                            />
                          ) : (
                            <TimePicker
                              value={absentReportTime}
                              format={"HH:mm"}
                              disabled
                              clearIcon={null}
                            />
                          )}
                        </Col>
                      </StyleRow>
                    </div>
                  </Spin>
                </StyledCard1>
              </Col>
            </>
          )}
        </Row>
      </StyledProfileContainer>
      <ChangePasswordModal
        openResetPasswordModal={openResetPasswordModal}
        toggleResetPasswordModal={() =>
          setOpenResetPasswordModal(!openResetPasswordModal)
        }
      ></ChangePasswordModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  notificationList: getNotificationList(state),
  userRoleId: getUserRoleId(state),
  profilePic: getProfilePic(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveProfilePic: setProfilePic,
      removeStoreData: clearStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
