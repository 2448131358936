import React, { useState,useEffect } from 'react';
import { Row, Col, Avatar, notification } from 'antd';

// Lodash
import {
  startCase
} from 'lodash';

import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import { getNotificationList } from 'redux/selectors';
import { map } from 'lodash';
import COLORS from 'common/Colors';
import { useHistory } from "react-router-dom";

const StyledNotificationRow = styled(Row)`
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 10px;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  .avatar-container {
    padding-top: 5px;
    padding-left: 5px;
  }
`;

function Notifications({
  notificationList,
  setOpen
}) {
  const data = map(notificationList, ({ sender_name, notification }, index) =>(
    {notification,sender_name}
  ))
  // console.log('notify',data);
  const datas = data.reverse();
  // console.log('notify1',datas);
  let history = useHistory();

  // console.log(notification,'notify');

  const setnavigate = () => {
    history.push(`calendar`);
    setOpen(false);
  }
  // const notificationReverse = notificationList.notification.notification-list



   


  const [searchterm,setSearchterm] = useState("");
  const [searchterm1,setSearchterm1] = useState(searchterm);
  

  useEffect(()=> {
    
    if (searchterm1==='task') {
      history.push(`task`);
      setOpen(false);
    }
    else if(searchterm.toLowerCase().includes("task".toLowerCase()))  {
      history.push(`task`);
      setOpen(false);
    }else if(searchterm.toLowerCase().includes("leave".toLowerCase()))  {
      history.push(`calendar`);
      setOpen(false);
    }
    
  },[searchterm]);
  // const [data1,setData2]=useState(notificationList);
  // const tbody = <div>
  //   {data1.map((row)=>{
  //     <ul>
  //       <li>{row.notification}</li>
  //     </ul>

  //   })}
  // </div>
  // const notificationReverse = notificationList.notification.notification-list
  return (
    <>
      <div className={ navigator.userAgent !== 'application' ?  'notification-popover1' : 'notification-popover-mobile' } >
        {
          notificationList.length === 0 ? 'No notification' : ''
        }
        {
          map(datas, ({ sender_name, notification }, index) =>
          (
            
            <StyledNotificationRow key={index}>
             
              
              

              <Col span={4} className='avatar-container'>
              
                <Avatar
                  style={{
                    backgroundColor: COLORS.PRIMARY,
                    verticalAlign: 'middle',
                  }}
                  size="default"
                >
                  {sender_name && startCase(sender_name.charAt(0))}
                </Avatar>
              </Col>
              <Col span={18}>
              <button style={{background:'none',border:'none',}} value={searchterm} onClick={()=>setSearchterm(notification)}>
                { notification  }
                </button>
              </Col>
              
            </StyledNotificationRow>
           
          )
          )
        }
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  notificationList: getNotificationList(state),
});

export default connect(mapStateToProps, null)(Notifications);
