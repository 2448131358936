import moment from 'moment';

export const addDaysinDate = (date, numberOfDays) => moment(date).add(numberOfDays, 'days').format('YYYY-MM-DD');

export const addMonthinDate = (date, numberOfMonths) => moment(date).add(numberOfMonths, 'months').format('YYYY-MM-DD');

export const dateInDetail = (date) => {
  const dateObj = moment(date || new Date());
  return {
    fullDate: date || '',
    date: dateObj.format('DD') || '',
    date_th: dateObj.format('Do') || '',
    month: dateObj.format('MM') || '',
    year: dateObj.format('YYYY') || '',
    day: dateObj.format('dddd') || '',
    shortDay: dateObj.format('ddd') || '',
    monthName: dateObj.format('MMMM') || '',
    shortMonthName: dateObj.format('MMM') || '',
    time: dateObj.format('hh:mm') || '',
    time24: dateObj.format('HH:mm') || '',
    meridiem: dateObj.format('a') || '',
    longDay: dateObj.format('dddd') || '',
    day_index: dateObj.day()
  };
};

export const momentDate = date => date !== '' ? moment(date, 'YYYY-MM-DD') : '';

export const getMySqlDate = date => date !== '' ? moment(date).format('YYYY-MM-DD') : '';

export const getServerDateToMySql = date => date !== '' ? moment(date, 'M/DD/YYYY, HH:mm:ss a').format() : '';

export const formatShortDate = date => {
  const dateObj = moment(date);
  return `${dateObj.format('Do')} ${dateObj.format('MMMM')}, ${dateObj.format('ddd')}`;
};

export const getinitialdate = () => {

  return moment("01-01-2022").format('DD-MM-YYYY');

}

export const getKenyanDateTime = () => {
  const d = new Date();
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  const nd = new Date(utc + (3600000 * (+3)));
  return moment(nd.toLocaleString('en-US'), 'M/DD/YYYY, HH:mm:ss a').format();
}

export const convertUTCtoKenyaTime = (date) => {
  const dt = moment(date);
  dt.add(3, 'hours');
  return dt;
}

export const getFirstDate = (date) => moment(date).startOf('month').format('YYYY-MM-DD');
export const getLastDate = (date) => moment(date).endOf('month').format('YYYY-MM-DD');

export const getMonthDateRange = (year, month) => {
  var startDate = moment([year, month - 1]);
  var endDate = moment(startDate).endOf('month');
  return { startDateOfMonth: startDate, lastDateOfMonth: endDate };
}

export const isDateBetweenTwoDates = (compareDate, startDate, endDate) => {
  compareDate = moment(compareDate);
  startDate = moment(startDate);
  endDate = moment(endDate);
  return compareDate.isBetween(startDate, endDate, 'days', '[]');
}

export const differenceBetweenTwoTime = (startDate, endDate) => {
  const sDate = moment(startDate, 'HH:mm');
  const eDate = moment(endDate, 'HH:mm');
  return eDate.diff(sDate, 'minutes');
};

export const generateHourMinutesFromMinutes = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins - (60 * hours)
  return `${hours <= 9 ? '0' : ''}${hours}:${minutes <= 9 ? '0' : ''}${minutes}`;
}

export const differenceBetweenTwoDates = (startDate, endDate) => {
  const sDate = moment(startDate);
  const eDate = moment(endDate);
  return sDate.diff(eDate, 'days');
}