import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, message } from 'antd';
import Button from 'atoms/Button';
import moment from 'moment';
import styled from '@emotion/styled';
import { CSVLink } from "react-csv";
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getAllDepartmentList,
  getBreakTypeList,
  getShiftTimeMaster,
} from 'redux/selectors';

import { CALL_API } from 'common/API';
import { MONTH_LIST, REPORT_DURATION_TYPES, STATUS_CODE } from 'common/Constants';

import { addDaysinDate, dateInDetail, differenceBetweenTwoTime, generateHourMinutesFromMinutes, getKenyanDateTime, getMonthDateRange, getMySqlDate, momentDate, addMonthinDate } from 'utils/Date';
import COLORS from 'common/Colors';
import { includes, reduce, concat, find, get } from 'lodash';

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom :10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color:  ${COLORS.PRIMARY};
  width: initial;
  background:transparent;
`;
function BreakReport({
  userRoleId,
  breakTypeList,
  allDepartmentList,
  selectedDepartmentId
}) {
  const [leaveList, setLeaveList] = useState([]);
  const [CSVLeaveList, setCSVLeaveList] = useState([]);
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([])

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }


  const STAFF_LIST_ALL = leaveList.map((val) => {
    return { user_id: val.user_id, name: val.name }
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));

  const filterbreaklist = leaveList.filter(val => {
    if (form.getFieldValue('staff_name') === "") {
      return val;
    }
    else if (val?.user_id === form.getFieldValue('staff_name')) {
      return val;
    }
  })

  const CSVHeaders = [
    { label: "Employee ID", key: "employee_id" },
    { label: "Name", key: "name" },
    { label: "Department", key: "job_title_name" },
    { label: "Date", key: "date" },
    { label: "Start time", key: "start_datetime" },
    { label: "End time", key: "end_datetime" },
    { label: "Duration", key: "duration" },
    { label: "Break Type", key: "break_type" },
  ];

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      width: '8%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '12%',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: '10%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '7%',
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      }
    },
    {
      title: 'Day',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      }
    },
    {
      title: 'Start time',
      dataIndex: 'start_datetime',
      key: 'start_datetime',
      width: '5%',
      render: (start_datetime) => {
        const { time24 } = dateInDetail(start_datetime);
        return `${time24}`;
      }
    },
    {
      title: 'End time',
      dataIndex: 'end_datetime',
      key: 'end_datetime',
      width: '5%',
      render: (end_datetime) => {
        const { time24 } = dateInDetail(end_datetime);
        return `${time24}`;
      }
    },
    {
      title: 'Duration (in hrs)',
      dataIndex: 'duration',
      key: 'duration',
      width: '10%',
      render: (_, record) => {
        const diff = differenceBetweenTwoTime(record.start_datetime, record.end_datetime);
        return generateHourMinutesFromMinutes(diff);
      }
    },
    {
      title: 'Break Type',
      dataIndex: 'break_type',
      key: 'break_type',
      width: '10%',
    },
  ];

  useEffect(() => {
    list()
    getBreakReportData();
  }, [selectedDepartmentId, userRoleId, form.getFieldValue('duration_type'), form.getFieldValue('start_date'), form.getFieldValue('end_date'), form.getFieldValue('month'), form.getFieldValue('department'), form.getFieldValue('break_type')]);

  // Function to generate to report
  const getBreakReportData = async () => {
    setLeaveList([]);
    const {
      start_date,
      end_date,
      duration_type,
      month,
      department,
      break_type,
    } = form.getFieldsValue();
    setDisplayTableLoader(true);
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), 'index', 0)
    );
    const {
      code,
      breakList = []
    } = await CALL_API('break-report', 'post', {
      department: (userRoleId === 1 || userRoleId === 6) ? [department] : selectedDepartmentId,
      break_type,
      start_date: getMySqlDate(duration_type === 2 ? startDateOfMonth : start_date),
      end_date: getMySqlDate(duration_type === 2 ? lastDateOfMonth : end_date),
    });
    setDisplayTableLoader(false);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      let recordList = []
      breakList.map(list => {
        Object.entries(list).forEach(([key, value]) => {
          recordList = concat(recordList, value)
        })
      })
      setLeaveList(recordList.reverse());
    }
  }

  // Function to validate date range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }

  // Function to Prepare CSV
  const prepareCSV = async (event, done) => {
    if (filterbreaklist.length > 0) {
      setCSVLeaveList(
        reduce(filterbreaklist, function (result, leave) {
          const { date_th: startDate_th, shortMonthName: startMonthName, year: end_year } = dateInDetail(leave.date);
          const diff = differenceBetweenTwoTime(leave.start_datetime, leave.end_datetime);
          const hours = Math.floor(diff / 60);
          const minutes = diff - (60 * hours)
          result.push({
            "Employee ID": leave.employee_id,
            "Name": leave.name,
            "Department": leave.department,
            "Date": `${startDate_th} ${startMonthName} ${end_year}`,
            "Day": `${moment(leave.date).format("dddd")}`,
            "Start time": dateInDetail(leave.start_datetime).time24,
            "End time": dateInDetail(leave.end_datetime).time24,
            "Duration": `${hours}:${minutes}`,
            "Break Type": leave.break_type,
          });
          return result;
        }, [])
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col xs={24} sm={19} md={19} className='page-title-head'>
          <Space>
            <StyledSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              initialValues={{
                staff_name: '',
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(),)),
                department: '',
                break_type: '',
              }}
              onFinish={getBreakReportData}
            >
              <Form.Item
                name='duration_type'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder='Select...'
                  options={REPORT_DURATION_TYPES}
                />
              </Form.Item>

              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Form.Item name='start_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='Start date'
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name='end_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='End date'
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item
                        name='month'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select...'
                          options={monthList}
                        />
                      </Form.Item>
                    )
                  }
                }
              </Form.Item>
              <Form.Item
                name='staff_name'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder="Select Employee"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                  }
                  options={concat({
                    user_id: '',
                    name: 'All Employee'
                  }, STAFF_LIST)}
                  fieldNames={{
                    label: 'name',
                    value: 'user_id'
                  }}
                />
              </Form.Item>

              {
                (userRoleId === 1 || userRoleId === 6) && (
                  <Form.Item
                    name='department'
                  >
                    <Select
                      placeholder="Select Department"
                      options={concat({
                        department_id: '',
                        department_name: 'All Departments'
                      }, allDepartmentList)}
                      fieldNames={{
                        label: 'department_name',
                        value: 'department_id'
                      }}
                    />
                  </Form.Item>
                )
              }

              <Form.Item
                name='break_type'
              >
                <Select
                  showSearch
                  placeholder='Search break type'
                  name='break_type'
                  optionFilterProp='break_name'
                  filterOption={(input, option) => option.break_name.toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) => {
                    optionA.break_name.toLowerCase().localeCompare(optionB.break_name.toLowerCase())
                  }}
                  options={concat({
                    break_id: '',
                    break_name: 'All Break Types'
                  }, breakTypeList)}
                  fieldNames={{
                    label: 'break_name',
                    value: 'break_id'
                  }}
                />
              </Form.Item>
            </StyledSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={prepareCSV} disabled={leaveList.length === 0}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVLeaveList}
            filename={"Break-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterbreaklist}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='user_leave_id'
          pagination={filterbreaklist.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  allDepartmentList: getAllDepartmentList(state),
  breakTypeList: getBreakTypeList(state),
  shiftTimeMaster: getShiftTimeMaster(state)
});

export default connect(mapStateToProps, null)(BreakReport);
