import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Row, Col, Modal } from 'antd';
import Button from 'atoms/Button'

// Theme
import styled from "@emotion/styled";

// Common
import COLORS from 'common/Colors';
import { NON_EMPTY_REGEX, NON_EMPTY_SINGLE_NUMBER, STATUS_CODE } from 'common/Constants';
import { CALL_API } from 'common/API';
import { DEVICE } from 'common/Device';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 2rem 3rem 1rem 3rem;
  }
  .select-role-title {
    margin-bottom: 0rem;
  }
  .select-role-sub-title {
    color: #8a8a8a;
    padding-bottom: 2rem;
  }
  @media ${DEVICE.tablet}  {
    width: 100% !important;
    .ant-modal-body {
      padding: 1rem !important;
    }
  }
`

const ModalHeaderRow = styled.h3`
  padding-bottom: ${props => (props.isConfirmationModal === true ? `0rem` : `1rem`)};
  margin-bottom: ${props => (props.isConfirmationModal === true ? `0.25rem` : `0.5rem`)};
  font-weight: bold;
`

const ModalHeaderSubRow = styled.h4`
    padding-bottom: ${props => (props.isConfirmationModal === true ? `0rem` : `1rem`)};
    margin-bottom: 0rem;
    color: ${COLORS.SECONDARY_BLACK};
`

const StyleSubmitButton = styled(Button)`
    margin-top: 1.5rem;
    font-weight: bold;
    background: ${COLORS.PRIMARY};
    &:hover {
        background: ${COLORS.PRIMARY};   
    }
    width: 130px;
`

const StyledCodeTextBox = styled(Col)`
    padding: 0px 5px;
`

const StyledCodeBoxContainer = styled(Row)`
    padding: 1rem 0rem;
    .ant-input {
        height: 40px;
        text-align: center;
    }
`

const StyledErrorDiv = styled.div`
  color: #ff7a7a;
  font-weight: bold;
  font-size: small;
`

const ResetPasswordModal = ({ openResetPasswordModal, toggleResetPasswordModal }) => {
  const [resetPasswordForm] = Form.useForm();
  const [confirmationCodeForm] = Form.useForm();
  const codeRefArray = {
    'code1': useRef(),
    'code2': useRef(),
    'code3': useRef(),
    'code4': useRef(),
    'code5': useRef(),
    'code6': useRef(),
  }
  const [codeArray, setCodeArray] = useState({
    'code1': '',
    'code2': '',
    'code3': '',
    'code4': '',
    'code5': '',
    'code6': '',
  });
  const [confirmationCodeModal, setConfirmationCodeModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userDetails, setUserDetails] = useState({
    user_id: '',
    email_id: ''
  });

  const onResetPasswordFinish = async (values) => {
    setResetLoading(true);
    const {
      code,
      email_id,
      user_id
    } = await CALL_API('reset-password', 'post', {
      username: values.username,
      newpassword: values.newpassword,
    });
    setResetLoading(false);
    if (code === STATUS_CODE.SUCCESS) {
      setUserDetails({
        email_id,
        user_id
      })
      toggleResetPasswordModal();
      setConfirmationCodeModal(true);
      resetPasswordForm.resetFields();
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      setErrorMessage('Something is wrong with data.');
    } else if (code === STATUS_CODE.SAME_PASSWORD) {
      setErrorMessage('Please enter new password other than previous one.');
    }
  };

  const onResetPasswordFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onConfirmagionCodeFinish = async (values) => {
    setErrorMessage('');
    setConfirmLoading(true);
    const { user_id } = userDetails;
    const {
      code
    } = await CALL_API('validate-reset-password-code', 'post', {
      user_id,
      code: `${values.code1}${values.code2}${values.code3}${values.code4}${values.code5}${values.code6}`,
    });
    setConfirmLoading(false);
    if (code === STATUS_CODE.PASSWORD_UPDATED) {
      Modal.success({
        content: 'Password updated successfully.',
        onOk() {
          setConfirmationCodeModal(false);
        },
      });
    } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
      setErrorMessage('Something is wrong with data.');
    }
  };

  const onConfirmagionCodeFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const handlePaste = (event, number) => {
  //   console.log(number)
  //   const copiedText = event.clipboardData.getData('text');
  //   const codeTextArray = copiedText.split('');
  //   console.log(codeArray);
  //   // console.log(event.clipboardData.getData('text'));
  //   let array_ind = 1;
  //   // codeArray[`code${number+1}`].current.value = '2';
  //   // codeArray[`code${number + array_ind}`].current.value = codeArray[array_ind++];
  //   for (let ind=number; ind<6; ind++) {
  //     (function (index, codeArrayIndex, setCodeArray) {
  //       console.log(`${index}: ${codeTextArray[codeArrayIndex]}`)
  //       // console.log(codeArray[`code${index}`])
  //       // codeArray[`code${index}`].current.value = codeArray[codeArrayIndex];
  //       setCodeArray({
  //         ...codeArray,
  //         [`code${index}`]: codeTextArray[codeArrayIndex]
  //       })
  //     })(ind, array_ind, setCodeArray)
  //     array_ind++;
  //   }
  // };

  useEffect(() => {
    if (openResetPasswordModal) {
      setCodeArray({
        'code1': '',
        'code2': '',
        'code3': '',
        'code4': '',
        'code5': '',
        'code6': '',
      });
      resetPasswordForm.resetFields();
      confirmationCodeForm.resetFields();
    }
  }, [openResetPasswordModal])

  const saveCode = (event, number) => {
    setCodeArray({
      ...codeArray,
      [`code${number}`]: event.target.value
    })
    if (number < 6) {
      codeRefArray[`code${number + 1}`].current.focus();
    }
  }

  return (
    <>
      <StyledModal
        centered
        visible={openResetPasswordModal}
        footer={false}
        keyboard={false}
        maskClosable={false}
        width='45%'
        onCancel={() => toggleResetPasswordModal(false)}
      >
        <ModalHeaderRow>Reset Password</ModalHeaderRow>
        <form autocomplete="off" list="autocompleteOff">
          <Form
            form={resetPasswordForm}
            layout="vertical"
            name="basic"
            initialValues={{
              username: '',
              newpassword: '',
              confirmpassword: '',
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter username!',
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder="User Name"
              />
            </Form.Item>

            <Form.Item
              name="newpassword"
              required
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter new password!',
                },
              ]}
            >
              <Input.Password autoComplete="new-password" placeholder="New Password" />
            </Form.Item>

            <Form.Item
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: 'Please enter new password again!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Row>
              <Col span={24} className='align-center'>
                {
                  errorMessage && (
                    <StyledErrorDiv className='align-center'>
                      {errorMessage}
                    </StyledErrorDiv>
                  )
                }
                <Form.Item>
                  <StyleSubmitButton loading={resetLoading} htmlType="submit" type='primary'>Reset</StyleSubmitButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </form>
      </StyledModal>

      <StyledModal
        centered
        visible={confirmationCodeModal}
        footer={false}
        keyboard={false}
        maskClosable={false}
        width='45%'
        onCancel={() => setConfirmationCodeModal(false)}
      >
        <ModalHeaderRow isConfirmationModal={true}>Confirmation Code</ModalHeaderRow>
        <ModalHeaderSubRow isConfirmationModal={true}>Enter the 6 digit confirmation code we sent on</ModalHeaderSubRow>
        <ModalHeaderSubRow>{userDetails.email_id}</ModalHeaderSubRow>
        <Form
          form={confirmationCodeForm}
          layout="vertical"
          name="basic"
          initialValues={{
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: '',
          }}
          onFinish={onConfirmagionCodeFinish}
          onFinishFailed={onConfirmagionCodeFinishFailed}
        >
          <StyledCodeBoxContainer>
            <Col md={{ offset: 2, span: 20 }}>
              <Row>
                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code1'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={event => saveCode(event, 1)}
                      // value={codeArray['code1']}
                      // onPaste={event => handlePaste(event, 1)}
                      ref={codeRefArray['code1']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>

                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code2'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={event => saveCode(event, 2)}
                      // onPaste={event => handlePaste(event, 2)}
                      ref={codeRefArray['code2']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>

                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code3'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={event => saveCode(event, 3)}
                      // value={codeArray['code3']}
                      // onPaste={event => handlePaste(event, 3)}
                      ref={codeRefArray['code3']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>

                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code4'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={event => saveCode(event, 4)}
                      // value={codeArray['code4']}
                      // onPaste={event => handlePaste(event, 4)}
                      ref={codeRefArray['code4']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>

                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code5'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={event => saveCode(event, 5)}
                      // value={codeArray['code5']}
                      // onPaste={event => handlePaste(event, 5)}
                      ref={codeRefArray['code5']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>

                <StyledCodeTextBox span={4}>
                  <Form.Item
                    name='code6'
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_SINGLE_NUMBER,
                        message: '',
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onKeyDown={event => saveCode(event, 6)}
                      // value={codeArray['code6']}
                      // onPaste={event => handlePaste(event, 6)}
                      ref={codeRefArray['code6']}
                    />
                  </Form.Item>
                </StyledCodeTextBox>
              </Row>
            </Col>
          </StyledCodeBoxContainer>

          <Row>
            <Col span={24} className='align-center'>
              <Form.Item>
                <StyleSubmitButton loading={confirmLoading} htmlType="submit" type='primary'>Confirm</StyleSubmitButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledModal>
    </>
  );
}

export default ResetPasswordModal;
