import React, { useEffect, useState } from "react";
import {
  Card,
  Avatar,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { FileUnknownFilled } from "@ant-design/icons";
import Icon from "atoms/Icon";
import { includes } from "lodash";
// Redux
import { connect } from "react-redux";
import { getUserRoleId } from "redux/selectors";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { startCase } from "lodash";
import {
  convertUTCtoKenyaTime,
  differenceBetweenTwoDates,
  differenceBetweenTwoTime,
  generateHourMinutesFromMinutes,
  getKenyanDateTime,
} from "utils/Date";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import download from "downloadjs";
import ViewMore from "organisms/ViewMore";

const StyledCard = styled(Card)`
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  background: none;
  .ant-card-body {
    padding: 0px;
    padding-bottom: 15px;
    ${({ nobottompadding }) =>
      nobottompadding === 4 && `padding-bottom: 0px !important`}
  }
  .thumbnail {
    height: 100px;
    background: ${COLORS.DOCUMENT_BACKGROUND};
    border-bottom: solid 1px ${COLORS.GREY};
    margin-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const StyledAvatar = styled(Avatar)`
  vertical-align:'middle';
  background-color: ${COLORS.PRIMARY};
  font-size: 0.6rem;
  height: 18px;
  width: 18px;
  margin-right: 5px;
  .ant-avatar-string {
    margin-top: 0px;
    line-height: 18px;
  }
`;

const StyledIcon = styled(Icon)`
  height: 60px;
  width: 60px;
`;

const StyledIconNofile = {
  color: "green",
  fontSize: "50px",
  padding: "5px"
}

const StyleName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-size: 0.6rem;
  color: ${COLORS.GREY};
`;

const StyledDocumentName = styled(Row)`
  font-size: 0.8rem;
  font-weight: bold;
  padding-bottom: 10px;
  min-height: 80px;
  .name {
    text-align: left;
    overflow-wrap: break-word;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  float: right;
  padding: 5px 10px;
`;

const StyledModal = styled(Modal)`
  height: 100vh;
  max-width: 80rem;
  padding: 1%;
  width: 100%;

  .ant-modal-content {
    height: 100vh;
    width: 100%;
    box-shadow: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 80rem;
    transform: scale(0.85);
  }

  .iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: scale(0.85);
  }
  .file_fit {
    width: 100%;
    height: 100%;
  }
  .img_fit {
    max-width: 80vw;
    max-height: 100vh;
  }
`;

function Document({
  id,
  name,
  staffName,
  uploadTime,
  userRoleId,
  deleteDocument,
}) {
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewURL, setPreviewURL] = useState("");
  const todayDate = getKenyanDateTime();
  const uploadKenyaTime = convertUTCtoKenyaTime(uploadTime);
  const days = differenceBetweenTwoDates(todayDate, uploadKenyaTime);
  let uploadedTime = "";
  if (days < 1) {
    const timeDifference = differenceBetweenTwoTime(uploadKenyaTime, todayDate);
    if (timeDifference < 60) {
      uploadedTime = `${timeDifference} minutes ago`;
    } else {
      uploadedTime = `${generateHourMinutesFromMinutes(timeDifference)} hours ago`;
    }
  } else {
    uploadedTime = `${days} days ago`;
  }

  const handleMenuClick = async (e) => {
    if (includes(["jpg", "png", "jpeg", "svg", "pdf"], splitname)) {
      toggleLoadingDocuments(true);
      const { code, url } = await CALL_API("document-list", "post", {
        method: "preview-document",
        documentId: id,
      });
      if (code === STATUS_CODE.SUCCESS) {
        setPreviewURL(url);
        setIsPreviewModalOpen(true);
      } else {
        message.error("Something went wrong!! Please try again.");
      }
      toggleLoadingDocuments(false);
    }
  };

  const handleDownload = async (e) => {
    toggleLoadingDocuments(true);
    if (e.key === "1") {
      const { code, url } = await CALL_API("document-list", "post", {
        method: "preview-document",
        documentId: id,
      });
      if (code === STATUS_CODE.SUCCESS) {
        download(url);
      } else {
        message.error("Something went wrong!! Please try again.");
      }
    }
    toggleLoadingDocuments(false);
  };
  const splitname = name.split(".").pop();

  const [icon, seticon] = useState(
    <Icon name="xl"  style={{StyledIcon}}/>
  );

  useEffect(() => {
    if (splitname === "") {
      seticon(<Icon name="word" style={{StyledIcon}} />);
    } else if (splitname === "pdf") {
      seticon(<Icon name="pdf" style={{StyledIcon}} />);
    } else if ( splitname === "jpg" || splitname === "png" || splitname === "jpeg" || splitname === "svg" ) {
      seticon(<Icon name="image" style={{StyledIcon}} />);
    } else if (  splitname === "xls" || splitname === "csv" || splitname === "xlsx") {
      seticon(<Icon name="xl" style={{StyledIcon}} />);
    } else if (splitname === "text" || splitname === "doc") {
      seticon(<Icon name="word" style={{StyledIcon}} />);
    } else if (  splitname === "txt" || splitname === "doc" || splitname === "docx") {
      seticon(<Icon name="word" style={{StyledIcon}} />);
    } else if ( splitname === "mp4" || splitname === "avi" || splitname === "mov") {
      seticon(<Icon name="video" style={{StyledIcon}} />);
    } else if (splitname === "ppt" || splitname === "odp") {
      seticon(<Icon name="ppt" style={{StyledIcon}} />);
    } else if (splitname === "zip") {
      seticon(<Icon name="zip" style={{StyledIcon}} />);
    } else {
      seticon(
        <FileUnknownFilled
          style={StyledIconNofile}
        />
      );
    }
  }, [name]);

  const handleDelete = () => {
    deleteDocument(id);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleDownload}>
        Download
      </Menu.Item>
      {userRoleId === 4 && (
        <Menu.Item key="2" onClick={handleDelete}>
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Spin spinning={isLoadingDocuments}>
      <StyledCard nobottompadding={userRoleId}>
        <div onClick={handleMenuClick} className="icon">
          {icon}
        </div>
        <StyledDocumentName>
          <Col span={userRoleId === 4 ? 18 : 18} className="name">
            <ViewMore value={name} count={20} />
          </Col>
          <Col span={6}>
            {
              <StyledDropdown overlay={menu}>
                <MoreOutlined />
              </StyledDropdown>
            }
          </Col>
        </StyledDocumentName>
        {userRoleId !== 4 && (
          <StyleName>
            <Tooltip title={`${staffName} - ${uploadedTime}`}>
              <Col span={20}>
                <StyledAvatar
                  size="default"
                >
                  {startCase(staffName.charAt(0))}
                </StyledAvatar>
                {staffName} - {uploadedTime}
              </Col>
            </Tooltip>
          </StyleName>
        )}
      </StyledCard>
      <StyledModal
        centered={true}
        visible={isPreviewModalOpen}
        footer={false}
        keyboard={false}
        width="100%"
        onOk={() => setIsPreviewModalOpen(false)}
        onCancel={() => setIsPreviewModalOpen(false)}
      >
        {includes(["jpg", "png", "jpeg", "svg"], splitname) ? (
          <div className="img">
            <img src={previewURL} className="img_fit" alt="Preview" />
          </div>
        ) : (
          <div className="iframe">
            <iframe className="file_fit" src={previewURL} align="middle" />
          </div>
        )}
      </StyledModal>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(Document);
