import React from 'react';
// import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import styled from "@emotion/styled";

// Common
import COLORS from 'common/Colors';

const StyledButton = styled(AntButton)`
  margin-left: 7px;
  ${({ type }) =>
    type &&
    type === 'primary' &&
    `
    background: ${(props) => props.theme.PRIMARY};
    color: ${(props) => props.theme.SECONDARY};
  `}
  ${({ type }) =>
    type &&
    type === 'secondary' &&
    `
    background: ${(props) => props.theme.SECONDARY};
    color: ${(props) => props.theme.PRIMARY};
  `}
  :hover {
    ${({ type }) =>
      type &&
      type === 'primary' &&
      `
    background-color: ${(props) => props.theme.PRIMARY};
    color: ${COLORS.TEXT.WHITE};
  `}
    ${({ type }) =>
      type &&
      type === 'secondary' &&
      `
    background: ${(props) => props.theme.SECONDARY};
    color: ${(props) => props.theme.PRIMARY};
  `}
  }
`;

const Button = (props) => {
  return (
    <StyledButton {...props}>{props.children || ''}</StyledButton>
  )
};

export default Button;
