import React from 'react';
import { Modal, Button } from 'antd';
import {
  WarningFilled,
} from '@ant-design/icons';

import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
  }
  .ant-modal-title {
    text-align: center;
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 10px;
  }
  .ant-modal-footer {
    border-top: none;
    padding-bottom: 15px;
  }
`;

const StyledExclamationIcon = styled(WarningFilled)`
  color: #FFCC00;
  font-size: 3rem;
  padding-bottom: 10px;
`;

const StyledTryAgainButton = styled(Button)`
  font-weight: bold;
`;

function CheckInProblemModal({
  isModalVisible,
  tryAgain,
  closeModal
}) {
  const handleOk = () => {
    // if (breakType) {
    //   startBreak();
    // } else {
    //   setErrorMessage('Select break type');
    // }
  };

  return (
    <StyledModal
      title=""
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModal}
      width={300}
      footer={[
        <StyledTryAgainButton type="link" onClick={tryAgain}>Try Again</StyledTryAgainButton>
      ]}
    >
      <StyledExclamationIcon />
      <h3 style={{ marginBottom: '0px' }}>Problem checking in</h3>
      <h5>Sorry, it looks like you are out of range</h5>
    </StyledModal>
  );
}

export default CheckInProblemModal;
