import React,{useEffect, useRef, useState} from 'react'
import { useParams } from "react-router-dom";
import { Row, Col, Card, Avatar, Button} from "antd";
import COLORS from "common/Colors";
import styled from "styled-components";
import { connect } from "react-redux";
import { utils, writeFileXLSX } from 'xlsx';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    getUserList,
    getAllDepartmentList,
    getLocations,
} from "redux/selectors";
import {startCase } from "lodash";
import moment from "moment";
import download from 'downloadjs';

const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  .my-1 {
    display: flex;
    word-wrap: break-word;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  .main_row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 912px) {
    .second_half_info {
      margin-top: -25px;
    }
  }
`;

const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  font-size: 3rem;
  height: 100px;
  width: 100px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const EmployeeDetails =({id,
    name,
    email_id,
    dept_name,
    userRoleId,
    KRA_pin, 
  NHIF, 
  NSSF, 
  employer_id, 
  joining_date, 
  date_of_birth,
    profile,
    userList,
    ClientId,
    departmentList,
    locationList}) =>{

    const StyledButton = styled(Button)`
    width: 120px;
    border: 1px solid ${COLORS.PRIMARY};
    color:  ${COLORS.PRIMARY};
    width: initial;
    background:transparent;
  `;

const componentRef = useRef(null);

const [imageLoaded, setImageLoaded] = useState(false);

// const exportToPDF = () => {
//     const input = componentRef.current;
//     if (imageLoaded) {
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');
//       const img = new Image();
//       img.src = profile;
//       img.onload = () => {
//         canvas.width = img.width;
//         canvas.height = img.height;
//         context.drawImage(img, 0, 0, img.width, img.height);
//         const imageDataUrl = canvas.toDataURL('image/jpeg');
  
//         html2pdf(input, {
//           margin: 10,
//           filename: 'employee_details.pdf',
//           image: { type: 'jpeg', quality: 0.98, dataurl: imageDataUrl },
//           html2canvas: { scale: 2 },
//           jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//         });
//       };
//     } else {
//       console.warn('Image not loaded yet. Please wait and try again.');
//     }
//   };
  
  useEffect(()=>{
    setImageLoaded(profile);
  },[profile])
const exportToPDF = () => {
    const input = componentRef.current;
  
    if (imageLoaded) {
      html2canvas(input, { scale: 2, useCORS: true })
        .then((canvas) => {
          const imageDataUrl = canvas.toDataURL('image/jpeg');
  
          html2pdf(input, {
            margin: 10,
            filename: 'employee_details.pdf',
            image: { type: 'jpeg', quality: 0.98, dataurl: imageDataUrl },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          });
        })
        .catch((error) => {
          console.error('Error converting to canvas:', error);
        });
    } else {
      console.warn('Image not loaded yet. Please wait and try again.');
    }
  };
  

    return(
        <>
        
        <Row className="profile-row" gutter={24} id="Details" ref={componentRef}>
        <Col md={24} sm={24} xs={24} lg={24}>
          <StyledCard>
            <Row className="main_row">
              <Col md={24} sm={24} xs={24} lg={24} className="text-center">
                <StyledStaffNameContainer>
                  {profile === null || profile === undefined || profile === 'null' ? (
                    <>
                      <StyledAvatar size="default">
                        {startCase(name).charAt(0)}
                      </StyledAvatar>
                    </>
                  ) : (
                    <>
                      <StyledAvatar src={profile} size="default"></StyledAvatar>
                    </>
                  )}
                </StyledStaffNameContainer>
              </Col>
                <Col md={24} sm={24} xs={24} lg={12}>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Name</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={14}>
                      {name}
                    </Col>
                  </StyledRow>
                
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Joining date</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={14}>
                      {moment(joining_date).format("DD-MMMM-YYYY")}
                    </Col>
                  </StyledRow>


                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>NHIF</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={14}>
                      {NHIF}
                    </Col>
                  </StyledRow>

                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>NSSF</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={14}>
                      {NSSF}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Department</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={14}>
                    {dept_name}
                    </Col>
                  </StyledRow>
                 
                </Col>
                <Col
                  md={24}
                  sm={24}
                  xs={24}
                  lg={12}
                >
                 
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>KRA pin</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {KRA_pin}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Employer_id</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {employer_id}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Role Id</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                      {userRoleId}
                    </Col>
                  </StyledRow>
                  <StyledRow className="my-4">
                    <Col className="my-1" xs={8} lg={8}>
                      <b>Date of Birth</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={2}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={10}>
                    {moment(date_of_birth).format("DD-MMMM-YYYY")}
                    </Col>
                  </StyledRow>
                 
                </Col>
                
              </Row>
              <StyledRow>
                    <Col className="my-1" xs={8} lg={4}>
                      <b>Email Id</b>
                    </Col>
                    <Col className="my-1" xs={1} lg={1}>
                      <b>:</b>
                    </Col>
                    <Col className="my-1" xs={10} lg={19}>
                      {email_id}
                    </Col>
                  </StyledRow>
            </StyledCard>
          </Col>
          </Row>
          <StyledButton onClick={exportToPDF} >
            Export PDF file
          </StyledButton>
          </>
    )
}
const mapStateToProps = (state) => ({
    userList: getUserList(state),
    departmentList: getAllDepartmentList(state),
    locationList: getLocations(state),
  });
export default connect(mapStateToProps)(EmployeeDetails);