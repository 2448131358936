import React, { useEffect, useState } from "react";
import { Card, Avatar, Tooltip, Row, Col, Menu, Dropdown, Modal } from "antd";
// Redux
import { connect } from "react-redux";
import {
  getUserName,
  getUserRoleId,
  getUnreadChatMessageList,
} from "redux/selectors";
import styled from "@emotion/styled";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { startCase } from "lodash";
import COLORS from "common/Colors";
import ViewMore from "organisms/ViewMore";
import EmployeeDetails from "pages/EmployeeDetails";

const StyledCard = styled(Card)`
  min-height: 350px;
  padding-left: 1%;
  text-align: center;
  border-radius: 5px;
  padding: 2%;
  position: relative;
  .threedot {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }
  .ant-card-body {
    padding-bottom: 15px !important;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  font-size: 3rem;
  height: 100px;
  text-align: center;
  width: 100px;
  .ant-avatar-string {
    margin-top: 2rem;
  }
`;

const StyleName = styled.h1`
  padding-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: #5b86e5;
  word-wrap: break-word;
`;

const StyleEmployeeId = styled.h2`
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-align: left;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
`;

const StyleEmailId = styled.h3`
  text-align: left;
  padding: 5px;
  font-size: 0.7rem;
  color: #5b86e5;
  word-wrap: break-word;
`;

const StyleDepartment = styled.h4`
  display: flex;
  gap: 5px;
  text-align: left;
  width: 100%;
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  b {
    white-space: nowrap;
  }
`;

const StyleRole = styled.h5`
  padding: 5px;
  font-size: 0.8rem;
  margin-bottom: 0px;
  text-align: left;
`;

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

function EmployeeCard({
  id,
  name,
  email_id,
  Department,
  Role,
  ViewEmployee,
  data,
  deletedEmployee,
  profile,
  KRA_pin,
  NHIF,
  NSSF,
  employer_id,
  joining_date,
  date_of_birth,
  dept_name,
}) {

  const [updateProfile, setUpdatedProfile] = useState("");
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const menu = (
    <StyledViewMenu>
      <Menu>
        <Menu.Item
          key="edit"
          onClick={({ key, domEvent }) => {
            if (key === "edit") {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              ViewEmployee(data);
            }
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="terminate"
          onClick={({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            deletedEmployee(data.userId);
          }}
        >
          Terminate
        </Menu.Item>
      </Menu>
    </StyledViewMenu>
  );

  useEffect(() => {
    setUpdatedProfile(profile);
  }, [updateProfile]);

  return (
    <>
      <StyledCard onClick={() => setOpen(true)}>
        <Row>
          <Col className="threedot">
            {data.status_id === 1 && (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomRight"
              >
                <MoreVertIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Dropdown>
            )}
          </Col>
        </Row>
        {profile === null ||
        profile === "" ||
        profile === undefined ||
        profile == "null" ? (
          <StyledAvatar size="default">
            {startCase(name?.charAt(0))}
          </StyledAvatar>
        ) : (
          <StyledAvatar src={profile} size="default"></StyledAvatar>
        )}
        <StyleName>
          <Tooltip title={name}>{name}</Tooltip>
        </StyleName>
        <div>
          <StyleEmployeeId>
            <b> Employee ID : </b> {id}
          </StyleEmployeeId>
          <StyleDepartment>
            <b> Department : </b>
            <>{<ViewMore value={Department} />}</>
          </StyleDepartment>
          <StyleRole>
            <b> Role : </b> {Role}
          </StyleRole>
          <StyleEmailId>
            <Tooltip title={email_id}>
              <b> Email Id : </b>
              {email_id}
            </Tooltip>
          </StyleEmailId>
        </div>
      </StyledCard>
      <StyledModal
        title="Employee Details"
        visible={open}
        onCancel={handleCancel}
        width={700}
        footer={[]}
      >
        <EmployeeDetails
          id={id}
          name={name}
          email_id={email_id}
          dept_name={dept_name}
          userRoleId={Role}
          profile={profile}
          KRA_pin={KRA_pin}
          NHIF={NHIF}
          NSSF={NSSF}
          employer_id={employer_id}
          joining_date={joining_date}
          date_of_birth={date_of_birth}
        />
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
});

export default connect(mapStateToProps, null)(EmployeeCard);
