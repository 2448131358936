import React, { useEffect, useState } from 'react';
import { Modal, Spin, Button, Form, DatePicker, Select, Table, message, Tooltip } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  EyeFilled,
  BellFilled
} from '@ant-design/icons';

// Redux
import { connect } from 'react-redux';
import {
  getStatusListFromID,
  getAllDepartmentList,
  getUserRoleId
} from 'redux/selectors';
import { momentDate, getKenyanDateTime, addDaysinDate, dateInDetail, getMySqlDate } from 'utils/Date';
import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { concat, filter } from 'lodash';

const StyledTable = styled(Table)`
  padding-top: 20px;
  table{
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.GREY}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 10px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function LeaveList({
  isModalVisible,
  closeModal,
  date,
  status_id,
  department_id,
  statusListFromID,
  allDepartmentList,
  userRoleId
}) {
  const [form] = Form.useForm();
  const [leaves, setLeaveList] = useState([]);
  const [displayTableSpinner, toggleTableSpinner] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  useEffect(() => {
    if (date && status_id) {
      form.setFieldsValue({
        start_date: momentDate(date),
        end_date: momentDate(date),
        status_id,
        department_id: ''
      })
      getLeaveList();
      setSelectedLeaveType(status_id);
    }
  }, [isModalVisible]);

  useEffect(() => {
    getLeaveList();
  }, [form.getFieldValue('end_date'), form.getFieldValue('start_date'), form.getFieldValue('status_id'), form.getFieldValue('department_id')])

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'user_id',
      key: 'user_id',
      width: '12%',
      align: 'center'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      align: 'center'
    },
    {
      title: 'Department',
      dataIndex: 'job_title_name',
      key: 'job_title_name',
      width: '10%',
      align: 'center'
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        if (record.new_start_date === null) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY")
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }

      }
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        if (record.new_end_date === null) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }

      }
    },
    {
      title: 'Leave Type',
      dataIndex: 'leave_category_name',
      key: 'leave_category_name',
      width: '15%',
      align: 'center',
      render: (_, record) => {
        if (record.new_leave_category_name === null) {
          return record.leave_category_name;
        } else {
          return record.new_leave_category_name;
        }
      }
    },
    {
      title: 'Days',
      dataIndex: 'number_of_days',
      key: 'number_of_days',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        if (record.new_number_of_days === null) {
          return record.number_of_days;
        } else {
          return record.new_number_of_days;
        }
      }
    }
  ];

  if ((userRoleId === 1 && selectedLeaveType === 4) || (userRoleId === 6 && selectedLeaveType === 4)) {
    columns.push(
      {
        title: 'Amended Leave',
        dataIndex: 'having_cancel_request',
        key: 'having_cancel_request',
        align: 'center',
        width: '10%',
    
        render: (having_cancel_request) => {
          if (having_cancel_request === 1) {
            return "Yes";
    
          } else {
            return "No"
          }
        }
      },  
      {
        title: 'Action',
        dataIndex: 'address',
        key: 'address',
        width: '10%',
        align: 'center',
        render: (_, record) => (
          <Tooltip title='Remind HOD & Assistant HOD' placement='left'>
            <BellFilled className='action-button' onClick={() => remindSuperior(record)} />
          </Tooltip>
        )
      },
   
    );
  }

  const remindSuperior = (leaveRecord) => {
    const { confirm } = Modal;
    confirm({
      icon: '',
      content: 'Do you Want to send reminder notification to HOD & Assistant HOD?',
      async onOk() {
        const { user_leave_id, user_id } = leaveRecord;
        const {
          code
        } = await CALL_API('leave-notification-to-hod-assistant-hod', 'post', {
          user_leave_id,
          user_id
        });
        if (code === STATUS_CODE.SUCCESS) {
          message.success(`Notification has been sent.`);
        } else {
          message.error(`Something went wrong. Please try again.`);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const getLeaveList = async () => {
    const { status_id, start_date, end_date, department_id } = form.getFieldsValue();
    setSelectedLeaveType(status_id);
    toggleTableSpinner(true);
    const {
      code,
      leaves
    } = await CALL_API('leave-list', 'post', {
      status_id: status_id == 4 ? '' :  status_id,
      department_id: [department_id],
      selected_role_id: 1,
      start_date: getMySqlDate(start_date),
      end_date: getMySqlDate(end_date),
    });
    toggleTableSpinner(false);
    if (code === STATUS_CODE.SUCCESS) {
      let leaveList1 = []
      leaves.map(list => {
        Object.entries(list).forEach(([key, value]) => {
          leaveList1 = concat(leaveList1, value)
        })
      })
      if (status_id == 4) {
        setLeaveList(filter(leaveList1, ({status_id, having_cancel_request }) => (status_id === 4 || having_cancel_request === 1)));
      }
      else if (status_id == 5){
        setLeaveList(filter(leaveList1, ({having_cancel_request }) => ( having_cancel_request === 0)))
      }
      else{
        setLeaveList(leaveList1)
      }
    }
  }

  const restrictPastDate = (current, element) => {
    let customDate = momentDate().format('YYYY-MM-DD');
    if (element === 'end_date') {
      return current < moment(form.getFieldValue('start_date'));
    }
    return current && current < moment(customDate, 'YYYY-MM-DD');
  }

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }

  return (
    <StyledModal
      title=""
      visible={isModalVisible}
      onCancel={closeModal}
      width='75%'
      footer={[]}
    >
      <div>
        <Form
          form={form}
          name='leavelist_form'
          layout='inline'
          initialValues={{
            status_id: '',
            start_date: momentDate(getKenyanDateTime()),
            end_date: momentDate(addDaysinDate(getKenyanDateTime(), 7)),
            department_id: '',
          }}
          onFinish={getLeaveList}
        >
          <Form.Item
            name='status_id'
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select
              placeholder='Select...'
              style={{
                width: 200,
              }}
              items={statusListFromID}
              fieldNames={{
                label: 'status_name',
                value: 'status_id'
              }}
              options={statusListFromID}
            />
          </Form.Item>

          <Form.Item name='start_date'
            rules={[
              () => ({
                validator(rule, value) {
                  const date = moment(value);
                  return date.isValid() ? Promise.resolve() : Promise.reject();
                }
              })
            ]}
          >
            <DatePicker
              placeholder='Start date'
              disabledDate={(current) => restrictPastDate(current, 'start_date')}
              onChange={validateDateRange}
              allowClear={false}
            // disabled={isViewOnly}
            />
          </Form.Item>

          <Form.Item
            name='end_date'
            rules={[
              () => ({
                validator(rule, value) {
                  const date = moment(value);
                  return date.isValid() ? Promise.resolve() : Promise.reject();
                }
              })
            ]}
          >
            <DatePicker
              placeholder='End date'
              disabledDate={(current) => restrictPastDate(current, 'end_date')}
              onChange={validateDateRange}
              allowClear={false}
            // disabled={isViewOnly}
            />
          </Form.Item>

          <Form.Item
            name='department_id'
          >
            <Select
              style={{ width: 200 }}
              placeholder="Select Department"
              options={concat({
                department_id: '',
                department_name: 'All'
              }, allDepartmentList)}
              fieldNames={{
                label: 'department_name',
                value: 'department_id'
              }}
            />
          </Form.Item>

          {/* <Button type="primary" htmlType='submit'>Search</Button> */}
        </Form>

        <Spin spinning={displayTableSpinner}>
          <StyledTable
            dataSource={leaves}
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
            rowKey='user_leave_id'
          />
        </Spin>
      </div>
    </StyledModal>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps, null)(LeaveList);
