import React, { useEffect, useState } from 'react';
import { Column,Line } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function YearwiseHeadCount({ report }) { 
  const config = {
    data:report,
    xField: 'Year',
    yField: 'count',
    seriesField:'type',
    height: 300,
    label:false,
    point: {
      size: 5,
      // shape: 'diamond',
    //   style: {
    //     fill: 'white',
    //     stroke: '#5B8FF9',
    //     lineWidth: 2,
    //   },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          // stroke: '#000',
          // fill: 'red',
        },
      },
    },
    yAxis: {
        // max: isNaN(max(map(reportData, 'count'))) ? 100 : max(map(reportData, 'count')) + 15,
        grid: {
          line: {
            style: {
              opacity: 0
            }
          }
        }
      },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    }
  return (
    <>      
      <StyledTitle>
          Headcount Evolution
      </StyledTitle>
      <StyledChartContainer>
          <Line
            {...config}
           />
      </StyledChartContainer>
    </>
  );
}


export default YearwiseHeadCount