import React from 'react';
import { Modal } from 'antd';
import {
  CheckCircleFilled,
} from '@ant-design/icons';

// Redux
import { connect } from 'react-redux';
import { getToggleSuccessModal, getSuccessModalMessage } from 'redux/selectors';
import { bindActionCreators  } from 'redux';
import { toggleSuccessModal } from 'redux/actions';
import styled from '@emotion/styled';

const StyledSuccessModal = styled(Modal)`
  .ant-modal-body {
    text-align: center;
    font-weight: bold;
    padding: 40px 10px;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .anticon-check-circle {
    font-size: 4rem;
    display: block;
    padding: 10px;
    color: #2CFF00;
  }
`;

function SuccessModal({
  isOpenDrawer,
  updateSuccessModal,
  successModalMessage
}) {
  return (
    <StyledSuccessModal
      title=""
      visible={isOpenDrawer}
      centered
      closable
      width={400}
      footer={[]}
      onCancel={() => updateSuccessModal({
        visibility: false,
        message: ''
      })}
    >
      <CheckCircleFilled />
      {successModalMessage}
    </StyledSuccessModal>
  );
}

const mapStateToProps = state => ({
  isOpenDrawer: getToggleSuccessModal(state),
  successModalMessage: getSuccessModalMessage(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
	{
    updateSuccessModal: toggleSuccessModal,
	},
	dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
