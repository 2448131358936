import React from "react";
import { Line } from "@ant-design/plots";
import styled from "@emotion/styled";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .title {
    display: flex;
    justify-content: space-between;
  }
`;

function ProductivityTopAreasAttrition({ data }) {

  const stateStyle = {
    shadowBlur: 4,
    stroke: "#000",
    fill: "red",
  };

  const config = {
    data: data,
    xField: "department_name",
    yField: "total_users",
    height: 300,
    label: false,
    point: {
      size: 5,
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: stateStyle,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return (
    <>
      <StyledTitle>
        <div className="title">Top Areas of Attrition</div>
      </StyledTitle>
      <StyledChartContainer>
        <Line {...config} />
      </StyledChartContainer>
    </>
  );
}

export default ProductivityTopAreasAttrition;
