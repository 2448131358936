import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import { getMySqlDate, momentDate } from "utils/Date";
import { concat } from "lodash";
import {
  NON_EMPTY_REGEX,
  NON_EMPTY_NUMBER,
  VALIDATE_SYMBOL,
  STATUS_CODE,
} from "common/Constants";
import { getAllDepartmentList, getLocations } from "redux/selectors";
import { get, includes } from "lodash";
import { useDropzone } from "react-dropzone";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #5b86e5;
    color: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  background: #5b86e5;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

function AddEmployee({
  isModalVisible,
  closeModal,
  getEmployeeList,
  allDepartmentList,
  locationList,
  EmployeeList,
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [filename, setFileName] = useState("");
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");

  useEffect(() => {
    setProfileUploaded(false);
  }, []);

  const emailList = EmployeeList?.map((user) => user.EmailId);
  const empIdList = EmployeeList?.map((user) => user.EmployeeID);
  const userNameList = EmployeeList?.map((user) => user.username.toLowerCase());

  const onFinish = async (values) => {
    toggleLoader(true);
    setUniqueId(generateUniqueId());
    const {
      employee_id,
      staff_department_id,
      hod_department_id,
      first_name,
      last_name,
      email_id,
      username,
      password,
      role_id,
      gender_name,
      start_date,
      end_date,
      national_id,
      nssf_id,
      nhif_id,
      kra_pin,
      overtime,
      salary,
      restdays,
      halfpay,
      date_of_birth,
    } = values;

    await CALL_API("admin-employee", "post", {
      employeeId: employee_id,
      first_name: first_name,
      last_name: last_name,
      email_id: email_id,
      username: username,
      password: password,
      role_id: role_id,
      startDate: getMySqlDate(start_date),
      endDate: end_date ? getMySqlDate(end_date) : "",
      gender: gender_name,
      location_id: locationId,
      NSSF: nssf_id,
      NHIF: nhif_id,
      KRApin: kra_pin,
      national_Id: national_id,
      status_id: 1,
      staff_department_id: staff_department_id,
      hod_department_id: hod_department_id,
      overtime_status: overtime,
      salary: salary ? salary : "",
      sick_leave_halfpay: halfpay == true ? "yes" : "no",
      rest_days: restdays == true ? "yes" : "no",
      date_of_birth: getMySqlDate(date_of_birth),
      profile_url: profileUploaded
        ? `https://tymeplusprofile-live.s3.af-south-1.amazonaws.com/${employee_id}/${storeId}.jpg`
        : null,
    });
    toggleLoader(false);
    form.resetFields();
    setFileName("");
    closeModal(true);
    getEmployeeList();
  };

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (form.getFieldValue("employee_id")) {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(acceptedFiles[0].path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            const { code } = await CALL_API("profile-upload", "post", {
              userid: form.getFieldValue("employee_id"),
              filetype: get(file, "type", ""),
              content: e.target.result,
              filename: uniqueId,
            });
            setStoreId(uniqueId);
          } else {
            setProfileUploaded(false);
            message.error("Please upload valid image file.");
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
  }, [acceptedFiles]);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  // Email Id Validation
  const validateEmail = (rule, value) => {
    if (value && emailList.includes(value.trim())) {
      return Promise.reject("This Email is already registered");
    }
    return Promise.resolve();
  };

  // Employee Id Validation
  const validateEmployeeId = (rule, value) => {
    if (value && empIdList.includes(value)) {
      return Promise.reject("Employee ID already exist");
    }
    return Promise.resolve();
  };


  // User Name Validation
  const validateUserName = (rule, value) => {
    if (value && userNameList.includes(value.toLowerCase())) {
      return Promise.reject("User name already exist");
    }
    return Promise.resolve();
  };

  return (
    <>
      <StyledModal
        title="Add Employees"
        visible={isModalVisible}
        onCancel={() => {
          closeModal(false);
          form.resetFields();
          setFileName("");
        }}
        width={600}
        footer={[]}
      >
        <Form
          form={form}
          name="addemployeeform"
          layout="vertical"
          initialValues={{
            employee_id: "",
            staff_department_id: "",
            first_name: "",
            last_name: "",
            email_id: "",
            username: "",
            password: "",
            role_name: "",
            gender: "",
            locationId: "",
            start_date: momentDate(new Date()),
            national_id: "",
            nssf_id: "",
            nhif_id: "",
            kra_pin: "",
            restdays: false,
            halfpay: false,
            date_of_birth: momentDate(new Date()),
          }}
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="employee_id"
                label="Employee ID"
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateEmployeeId },
                ]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select role",
                  },
                ]}
              >
                <Select
                  placeholder="Select role"
                  getPopupContainer={() => containerRef.current}
                >
                  <Option value="1">Super HOD</Option>
                  <Option value="2">HOD</Option>
                  <Option value="3">ASSISTANT HOD</Option>
                  <Option value="4">STAFF</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="staff_department_id"
                label="Staff Department"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select Staff Department name",
                  },
                ]}
              >
                <Select
                  options={concat(
                    {
                      department_id: "",
                      department_name: "Select Staff Department",
                    },
                    allDepartmentList
                  )}
                  fieldNames={{
                    label: "department_name",
                    value: "department_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>

            {(form.getFieldValue("role_id") == 2 ||
              form.getFieldValue("role_id") == 3) && (
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="hod_department_id"
                  label="Hod Department"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please Select Hod Department name",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    options={allDepartmentList}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    placeholder="Select Hod Department"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="first_name"
                label="First name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter First name",
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: "Name should not contain special characters",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="last_name"
                label="Last name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Last name",
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: "Name should not contain special characters",
                  },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Username",
                  },
                  // {
                  //   required: false,
                  //   pattern: VALIDATE_SYMBOL,
                  //   message: "Name should not contain special characters",
                  // },
                  { validator: validateUserName },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="email_id"
                label="Email ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter EmailID",
                  },
                  { validator: validateEmail },
                ]}
              >
                <Input placeholder="Email ID" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="gender_name"
                label="Gender"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select gender",
                  },
                ]}
              >
                <Select
                  placeholder="Select gender"
                  getPopupContainer={() => containerRef.current}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="overtime"
                label="Overtime Eligible"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  getPopupContainer={() => containerRef.current}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Location Name"
                name="locationId"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select Location",
                  },
                ]}
              >
                <Select
                  placeholder="Select Location"
                  options={concat(
                    {
                      location_id: "",
                      location_name: "Select Location",
                    },
                    locationList
                  )}
                  fieldNames={{
                    label: "location_name",
                    value: "location_id",
                  }}
                  value={locationId}
                  onChange={(val) => setLocationId(val)}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Start date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter start date!",
                  },
                  () => ({
                    validator(rule, value) {
                      const date = moment(value);
                      return date.isValid()
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                  }),
                ]}
              >
                <DatePicker
                  onChange={validateDateRange}
                  placeholder="Select start date"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.start_date !== currentValues.start_date
                }
              >
                {() => (
                  <Form.Item label="End date" name="end_date">
                    <DatePicker
                      placeholder="Select end date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="salary" label=" Salary">
                <Input placeholder="Salary" type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="national_id"
                label="National ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NationalID",
                  },
                ]}
              >
                <Input placeholder="National ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="nssf_id"
                label="NSSF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NSSF",
                  },
                ]}
              >
                <Input placeholder="NSSF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="nhif_id"
                label="NHIF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NHIF",
                  },
                ]}
              >
                <Input placeholder="NHIF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="kra_pin"
                label="KRA Pin"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter KRA Pin",
                  },
                ]}
              >
                <Input placeholder="KRA Pin" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Date of Birth"
                name="date_of_birth"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter date of birth!",
                  },
                ]}
              >
                <DatePicker
                  onChange={validateDateRange}
                  placeholder="Select date of birth"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-2">
              <Row>
                <Col xs={24} sm={24} md={12} className="">
                  <Form.Item name="halfpay" valuePropName="checked">
                    <Checkbox>Sick Leave(half-pay)</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="">
                  {/* <Form.Item name="restdays" valuePropName="checked">
                    <Checkbox>Rest Days</Checkbox>
                  </Form.Item> */}
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item name="profile" label="Profile Pic">
                <StyledDropzone {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>{filename !== "" ? <>{filename}</> : <> choose file</>}</p>
                </StyledDropzone>
              </Form.Item>
            </Col>
          </Row>
          <div className="align-center">
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              {showLoader ? "Create" : "Create"}
            </StyledButton>
            <StyledButton type="primary" onClick={() => Reset()}>
              Reset
            </StyledButton>
          </div>
        </Form>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
});

export default connect(mapStateToProps, null)(AddEmployee);
