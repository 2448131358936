import React from "react";
import { Layout, Menu, Skeleton, Badge, Drawer } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { size, times, map, intersection, get, filter, concat } from "lodash";
import adminlogo from "../assets/adminlogo.svg";
import _map from "lodash/map";
import logo from "../assets/tymeplus.png";
import COLORS from "common/Colors";
import { MENU } from "common/Constants";
import { useLocation } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLoadingDataFirstTime,
  getUnreadChatMessageList,
  getDepartmentUserList,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import { DEVICE } from "common/Device";

const { Item, SubMenu } = Menu;

const StyledLogo = styled.div`
  height: 40px;
  width: 40px;
  margin: 5px 15px 20px;
  text-align: center;
  background: #fff;
  img {
    margin-top: 5px;
  }
  @media ${DEVICE.tablet} {
    height: 25px;
    margin-bottom: 15px;
    img {
      height: 25px !important;
    }
  }
`;

const StyledMenuItem = styled(Item)`
  vertical-align: middle;

  &:after {
    background-color: red !important;
    border-right: 3px solid ${(props) => (props.selected ? `${COLORS.PRIMARY}` : `${COLORS.WHITE}`)} !important;
  }
  &.ant-menu-item-selected {
    background-color: ${(props) => props.selected ? `${COLORS.PRIMARY}` : "inherit"} !important;
    .anticon,
    a,
    svg {
      color: ${(props) => props.selected ? `${COLORS.WHITE}` : `${COLORS.BLACK}`};
      fill: ${(props) => props.selected ? `${COLORS.WHITE}` : `${COLORS.BLACK}`};
    }
  }
`;

const StyledMenuSkeletonContainer = styled.div`
  text-align: center;
  padding: 10px 20px;
  .ant-skeleton {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  .sidemenu {
    display: none;
  }
  @media ${DEVICE.tablet} {
    .sidemenu {
      width: 140px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 110px;
      transition: 350ms;
      z-index: 20;
    }
    .hidemenu {
      display: none;
    }
  }
`;

const StyledBadge = styled(Badge)`
  left: 10px;
  top: 4px;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .ant-badge-dot {
    width: 10px;
    height: 10px;
    animation: blinker 1s linear infinite;
  }
`;

const { Sider } = Layout;
const SideBar = ({
  defaultKey,
  activePage,
  collapsed,
  setPageTitle,
  userRoleId,
  isLoadingDataFirstTime,
  unreadChatMessageList,
  open,
  onClose,
  departmentUserList,
  userInfo,
  userList,
}) => {
  const menuSkeleton = (key) => (
    <Skeleton.Input block={true} active={true} size="small" key={key} />
  );
  const location = useLocation();

  const unreadDot = () => {
    var hrUser = filter(userList, { role_id: 1 });
    if (userRoleId == 2 || userRoleId == 3) {
      let result = map(concat(departmentUserList, hrUser), (e) => e.user_id);
      result = intersection(result, unreadChatMessageList);
      return size(result) ? true : false;
    } else if (userRoleId == 4) {
      let result = map(
        concat(
          filter(userList, {
            department_id: get(userInfo, "staff_department_id", ""),
          }),
          hrUser
        ),
        (e) => e.user_id
      );
      result = intersection(result, unreadChatMessageList);
      return size(result) ? true : false;
    }
    return size(unreadChatMessageList) ? true : false;
  };

  const logoStyle = {
    height: collapsed ? "25px" : "45px",
    marginTop: collapsed ? "15px" : "5px",
  };

  const containerStyle = {
    height: "100vh",
    background: "#fff",
  };

  const drawerStyle ={
    padding : '0px',
  }

  return (
    <Container style={containerStyle}>
      <div className="hidemenu">
        <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
          {userRoleId !== 5 ? (
            <StyledLogo>
              <img style={logoStyle} src={logo} alt="Tymeplus" />
            </StyledLogo>
          ) : (
            <StyledLogo>
              <img style={logoStyle} src={adminlogo} alt="Tymeplus" />
            </StyledLogo>
          )}
          {isLoadingDataFirstTime && (
            <StyledMenuSkeletonContainer>
              {times(10, (ind) => menuSkeleton(ind))}
            </StyledMenuSkeletonContainer>
          )}
          {!isLoadingDataFirstTime && (
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[activePage]}
              defaultOpenKeys={[defaultKey]}
            >
              {_map(MENU[userRoleId], (menu) => {
                return menu?.subMenu && menu?.subMenu?.length > 0 ? (
                  <SubMenu key={menu?.key} icon={menu?.icon} title={menu?.title}>
                    {_map(menu?.subMenu, (submenu) => {
                      return submenu?.subMenu && submenu?.subMenu?.length > 0 ? (
                        <SubMenu
                          key={submenu?.key}
                          icon={submenu?.icon}
                          title={submenu?.title}
                        >
                          {_map(submenu?.subMenu, (submenu) => {
                            return (
                              <StyledMenuItem
                                collapsed={collapsed ? "true" : "false"}
                                key={submenu?.key}
                                icon={submenu?.icon}
                                onClick={() => setPageTitle(submenu?.title)}
                                selected={
                                  location?.pathname.substr(1) == ""
                                    ? false
                                    : true
                                }
                              >
                                <Link to={`/${submenu.path}`}>
                                  {submenu?.title}
                                </Link>
                                <StyledBadge
                                  dot={menu?.path === "team" && unreadDot()}
                                />
                              </StyledMenuItem>
                            );
                          })}
                        </SubMenu>
                      ) : (
                        <StyledMenuItem
                          collapsed={collapsed ? "true" : "false"}
                          key={submenu?.key}
                          icon={submenu?.icon}
                          onClick={() => setPageTitle(submenu?.title)}
                          selected={
                            location?.pathname.substr(1) == "" ? false : true
                          }
                        >
                          <Link to={`/${submenu?.path}`}>{submenu?.title}</Link>
                          <StyledBadge
                            dot={menu?.path === "team" && unreadDot()}
                          />
                        </StyledMenuItem>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <StyledMenuItem
                    key={menu?.key}
                    icon={menu?.icon}
                    onClick={() => setPageTitle(menu?.title)}
                    selected={location?.pathname.substr(1) == "" ? false : true}
                  >
                    <Link to={`/${menu?.path}`}>{menu?.title}</Link>
                    <StyledBadge dot={menu?.path === "team" && unreadDot()} />
                  </StyledMenuItem>
                );
              })}
            </Menu>
          )}
        </Sider>
      </div>
      <div>
        <Drawer
          bodyStyle={drawerStyle}
          width={240}
          title={<img src={logo} width={120} alt="Tymeplus" />}
          placement="left"
          onClose={onClose}
          open={open}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[activePage]}
            defaultOpenKeys={[defaultKey]}
          >
            {_map(MENU[userRoleId], (menu) => {
              return menu?.subMenu && menu?.subMenu?.length > 0 ? (
                <SubMenu key={menu?.key} icon={menu?.icon} title={menu?.title}>
                  {_map(menu?.subMenu, (submenu) => {
                    return submenu?.subMenu && submenu?.subMenu?.length > 0 ? (
                      <SubMenu
                        key={submenu?.key}
                        icon={submenu?.icon}
                        title={submenu?.title}
                      >
                        {_map(submenu?.subMenu, (submenu) => {
                          return (
                            <StyledMenuItem
                              collapsed={collapsed ? "true" : "false"}
                              key={submenu?.key}
                              icon={submenu?.icon}
                              onClick={() => {
                                setPageTitle(submenu?.title);
                                onClose();
                              }}
                              selected={
                                location.pathname.substr(1) == "" ? false : true
                              }
                            >
                              <Link to={`/${submenu.path}`}>
                                {submenu?.title}
                              </Link>
                              <StyledBadge
                                dot={menu?.path === "team" && unreadDot()}
                              />
                            </StyledMenuItem>
                          );
                        })}
                      </SubMenu>
                    ) : (
                      <StyledMenuItem
                        collapsed={collapsed ? "true" : "false"}
                        key={submenu?.key}
                        icon={submenu?.icon}
                        onClick={() => {
                          setPageTitle(submenu?.title);
                          onClose();
                        }}
                        selected={
                          location?.pathname.substr(1) == "" ? false : true
                        }
                      >
                        <Link to={`/${submenu?.path}`}>{submenu?.title}</Link>
                        <StyledBadge
                          dot={menu?.path === "team" && unreadDot()}
                        />
                      </StyledMenuItem>
                    );
                  })}
                </SubMenu>
              ) : (
                <StyledMenuItem
                  key={menu?.key}
                  icon={menu?.icon}
                  onClick={() => {
                    setPageTitle(menu?.title);
                    onClose();
                  }}
                  selected={location?.pathname.substr(1) == "" ? false : true}
                >
                  <Link to={`/${menu?.path}`}>{menu?.title}</Link>
                  <StyledBadge
                    dot={
                      menu?.path === "team" && size(unreadChatMessageList)
                        ? true
                        : false
                    }
                  />
                </StyledMenuItem>
              );
            })}
          </Menu>
        </Drawer>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  departmentUserList: getDepartmentUserList(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(SideBar);
