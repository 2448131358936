import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  Modal,
  message,
  TimePicker,
} from "antd";
import Button from "atoms/Button";
import styled from "@emotion/styled";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getAllDepartmentList,
} from "redux/selectors";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { CSVLink } from "react-csv";
import { reduce, concat, uniqBy } from "lodash";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import NotFound from "organisms/NotFound";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding-left: 20px;
    padding: 10px;
    height: 50px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .camelcase {
    text-transform: capitalize;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin: 10px;
`;

const StyledButton1 = styled(Button)`
  border: 1px solid;
  margin-bottom: 2%;
`;

const IconStyle = {
  fontSize: "15px" 
}

function Shift({ userRoleId, selectedDepartmentId, allDepartmentList }) {
  const [shiftList, setShiftList] = useState([]);
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [shiftCSVlist, setShiftCSVlist] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [shiftFilterList, setShiftFilterList] = useState([]);
  const csvLinkEl = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    if (userRoleId !== 5) setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  const STAFF_LIST = uniqBy(
    shiftList.map((val) => {
      return { user_id: val?.user_id, name: val?.name };
    }),
    "user_id"
  );

  useEffect(() => {
    var result;
    if (selectedDepartment != "") {
      if (selectedStaff == "")
        result = shiftList?.filter((data) => data?.department_id === selectedDepartment);
      else
        result = shiftList?.filter((data) => data?.department_id === selectedDepartment && data?.user_id == selectedStaff );
    } else {
      if (selectedStaff == "") {
        result = shiftList;
      }
      else {
        result = shiftList?.filter((data) => data?.user_id == selectedStaff);
      }
    }
    setShiftFilterList(result);
  }, [selectedDepartment, shiftList, selectedStaff]);



  const CSVHeaders = [
    { label: "Employee Name", key: "name" },
    { label: "Department name", key: "department_name" },
    { label: "Shift start time", key: "shift_start_time" },
    { label: "Shift end time", key: "shift_end_time" },
    { label: "Shift Type", key: "shift_type" },
  ];
  const columns = [
    {
      title: "Employee name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Department name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Shift start time",
      dataIndex: "shift_start_time",
      key: "shift_start_time",
      width: "10%",
      render: (_, render) => {
        let date = render?.shift_start_time;
        return (
          <div >
            <TimePicker
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val)=>{onUpdate(render?.user_shift_Id, val, render?.shift_end_time); }}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
               
            />
          </div>
        );
      },
    },
    {
      title: "Shift end time",
      dataIndex: "shift_end_time",
      key: "shift_end_time",
      width: "10%",
      render: (_, render) => {
        let date = render?.shift_end_time;
        return (
          <div >
            <TimePicker
              defaultValue={moment(date, "HH:mm")}
              format={"HH:mm"}
              onChange={(val) => {onUpdate(render?.user_shift_Id, render?.shift_start_time, val)}}
              clearIcon={null}
              getPopupContainer={() => containerRef.current}
            />
          </div>
        );
      },
    },
    {
      title: "shift Type",
      dataIndex: "shift_type",
      key: "shift_type",
      width: "10%",
      className: "camelcase",
    },
  ];

  useEffect(() => {
    getRoleList();
  }, [setShiftList]);

  const getRoleList = async () => {
    try {
      setDisplayLocationLoader(true);
      const response = await CALL_API("user-shift", "get", {});
      setShiftList(response);
      setDisplayLocationLoader(false);
    } catch (error) {
      console.log("[getRoleList] Error-->", error);
    }
  };

  const shiftTimingerror = () => {
    Modal.info({
      title: "Shift working timings should be minimum 1 hour",
    });
  };

  const onUpdate = async (Id, start_time, end_time) => {
    let shiftStartTime = moment(start_time, "HH:mm").format("HH:mm")
    let shiftEndTime = moment(end_time, "HH:mm").format("HH:mm")
    if (shiftStartTime || shiftEndTime) {
   

      await CALL_API("user-shift", "patch", {
        user_shift_Id: Id,
        shift_start_time: shiftStartTime,
        shift_end_time: shiftEndTime,
        shift_type: "not general shift",
      });

      getRoleList();
 
    } 
  };

  const prepareCSV = async () => {
    if (shiftFilterList?.length > 0) {
      setShiftCSVlist(
        reduce(
          shiftFilterList,
          function (result, list) {
            result.push({
              "Employee name": list?.name,
              "department name": list?.department_name,
              "Shift start time": list?.shift_start_time,
              "Shift end time": list?.shift_end_time,
              "Shift Type": list?.shift_type,
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };
  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <>
      {userRoleId == 5 && (
        <Row className="page-title">
          <Col span={24} className="align-right">
            <StyledButton1
              type="link"
              onClick={prepareCSV}
            >
              Export CSV file
            </StyledButton1>
            <CSVLink
              header={CSVHeaders}
              data={shiftCSVlist}
              filename={"shift-management.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
          </Col>
        </Row>
      )}
      {userRoleId == 5 && (
        <>
          <StyledSelect
            className="selectbox"
            placeholder="Department"
            defaultValue={""}
            options={concat(
              {
                department_id: "",
                department_name: "All Departments",
              },
              allDepartmentList
            )}
            fieldNames={{
              label: "department_name",
              value: "department_id",
            }}
            onChange={(id) => {
              setSelectedDepartment(id);
            }}
          />
          <StyledSelect
            placeholder="Select Employee"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input?.toLowerCase())
            }
            options={concat(
              {
                user_id: "",
                name: "All Employee",
              },
              STAFF_LIST
            )}
            fieldNames={{
              label: "name",
              value: "user_id",
            }}
            onChange={(id) => setSelectedStaff(id)}
          />
        </>
      )}
      {userRoleId == 5 && (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            ref={containerRef}
            dataSource={shiftFilterList}
            columns={columns}
            // note : first parameter is needed
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="user_shift_Id"
            pagination={
              shiftFilterList?.length > 20 ? paginationOptions : false
            }
          />
        </Spin>
      )}
      {userRoleId != 5 && <NotFound />}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Shift);
