import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size, find, forEach, unionBy, has } from 'lodash';
import { Row, Col, Button } from 'antd';
import { DEVICE } from 'common/Device';
import { DatePicker, Space } from 'antd';
import YearwiseTotalHoursOvertime from'../molecules/YearwiseTotalHoursOvertime'
import YearwiseManagerToStaffRatio from 'molecules/YearwiseManagerToStaffRatio';
import YearwiseHeadCount from 'molecules/YearwiseHeadCount';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CALL_API } from 'common/API';
import moment from 'moment';
import { getUserRoleId } from 'redux/selectors';
import NotFound from 'organisms/NotFound';
import { connect } from 'react-redux';

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row{
    width:100%;
    display:flex;
    justify-content:space-between;
  }
  .select_field{
    display: flex;
    gap:10px;
  }
  .selectbox{
    width:200px;
    // width:100%;
  }
  @media ${DEVICE.tablet}  {
    .select_field{
      width:100%;
      display: flex;
      flex-direction:column;
    }
    .selectbox{
      width:100%;
    }
    .align-right{
      width:100%;
      margin-top:10px;
      float:right;
    }
  }

`;

function Trends({
  userRoleId
}) {
  const containerRef = useRef(null);
  const container1Ref = useRef(null);
  const defaultEndYear =  moment().year();
  const [ overtimeData, setOvertimeData ] = useState([])
  const [ headCountData, setHeadCountData ] = useState([])
  const [ startYear, setStartYear ] = useState(defaultEndYear-5)
  const [ endYear, setEndYear ] = useState(defaultEndYear)
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'pt', [reportHeight, reportHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, reportHeight, reportHeight);
      pdf.save('Trends.pdf');
    });
  }

  const getData = async() => {
    toggleReportLoader(true)
    const initialData = [];

    for (let year =  parseInt(startYear); year <=  parseInt(endYear); year++) {
      initialData.push({
        Hours: 0,
        Year: year,
        ManagerCount: 0,
        StaffCount: 0
      });
    }

    const {
      totalHoursOfOverTimeData,
      headCountEvolutionData
    } = await CALL_API('admin-trends', 'post', {
      method: 'adminTrends',
      parameters: {
        start_year: startYear,
        end_year: endYear
      },
    });
    const mappedOvertimeData = totalHoursOfOverTimeData.map(item => ({
      Year: item.year,
      Hours: parseInt(item.hours.split(':')[0])
    }));
    const mappedHeadCountData = headCountEvolutionData.map(item => ({
      Year: item.year,
      ManagerCount: item.manager_count,
      StaffCount: item.staff_count
    }));

    setOvertimeData(()=>{
      const mergedData = unionBy(initialData, mappedOvertimeData, 'Year');
      mergedData.forEach(item => {
        const Hours2 = (find(mappedOvertimeData, { 'Year': item.Year }) || {}).Hours || 0;
        item.Hours = Hours2;
      });
      return mergedData;
    })

    const data = () => {
      const mergedData = unionBy(initialData, mappedHeadCountData, 'Year');
      mergedData.forEach(item => {
        const ManagerCount = (find(mappedHeadCountData, { 'Year': item.Year }) || {}).ManagerCount || 0;
        const StaffCount = (find(mappedHeadCountData, { 'Year': item.Year }) || {}).StaffCount || 0;
        item.ManagerCount = ManagerCount;
        item.StaffCount = StaffCount
      });
      return mergedData;
    }

    var  reportData = []
    forEach(data(), function(obj) {
      var Year = obj.Year;
      if (has(obj, 'ManagerCount')) {
        reportData.push({ Year: Year, type: 'Manager', count: obj.ManagerCount });
      }
      if (has(obj, 'StaffCount')) {
        reportData.push({ Year: Year, type: 'Staff', count: obj.StaffCount });
      }
    });
    setHeadCountData(reportData)

    toggleReportLoader(false)
  }

  function handleStartYearChange(yearMoment) {
    setStartYear(yearMoment.format('YYYY'));
  }
 
  function handleEndYearChange(yearMoment) {
    setEndYear(yearMoment.format('YYYY'));   
  }
  useEffect(() => {
    getData()
  }, [startYear, endYear]);

  return (
    <> 
      {userRoleId == 5 && (
      <>          
      <StyledTitle  className='page-title-head'>
          Trends
      </StyledTitle>
      <StyledFilterRow>
        <Row className='top_row' >
          <div className='select_field' ref={container1Ref}>
              <DatePicker.YearPicker  getPopupContainer={() => container1Ref.current} className='selectbox' defaultValue={moment(`${startYear}`, 'YYYY')} placeholder='Select from year' clearIcon={null} onChange={handleStartYearChange} />
              <DatePicker.YearPicker  getPopupContainer={() => container1Ref.current} className='selectbox' defaultValue={moment(`${defaultEndYear}`, 'YYYY')} placeholder='Select to year' clearIcon={null} onChange={handleEndYearChange} />
          </div>
          <Col className='align-right'>
              <Button type="primary" onClick={generatePDF}><span className='add-title'>Download as PDF</span></Button>
          </Col>
        </Row>
      </StyledFilterRow>
      <StyledFilterRow gutter={16} ref={containerRef}>
         <Col md={24} sm={24} xs={24} xl={12}>
            <Spin spinning={displayReportLoader}>
               <YearwiseTotalHoursOvertime report={overtimeData} startYear={startYear} endYear={endYear}/>
            </Spin>
         </Col>
         <Col md={24} sm={24} xs={24} xl={12}>
           <Spin spinning={displayReportLoader}>
              <YearwiseHeadCount report={headCountData}/>
            </Spin>
         </Col>
         {/* <Col md={24} sm={24} xs={24} xl={12}>
            <Spin spinning={displayReportLoader}>
              <YearwiseManagerToStaffRatio/>
            </Spin>
         </Col> */}
      </StyledFilterRow>
      </>)}
      {userRoleId != 5 && <NotFound/>}
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state)
});

export default connect(mapStateToProps, null)(Trends)