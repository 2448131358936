const FONTS = {
  FAMILY: 'Roboto',

  SIZE: {
    XS: '10px',
    S: '11px',
    M: '12px',
    L: '14px',
    XL: '16px',
    '2XL': '20px',
  },
  
  WEIGHT: {  
    LIGHT: '200',
    NORMAL: '500',
    HEAVY: '600',
  },
};

export default FONTS;
