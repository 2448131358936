import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Table, Spin } from 'antd';

import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  margin-bottom: 25px;
  height:320px;
  // overflow-y: auto;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:320px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function RecentEmployeeList({ }) {
  const [employeeList, setEmployeeList] = useState([])
  const [ displayReportLoader, toggleReportLoader ] = useState(false);
  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeID',
      key: 'employeeID',
      width: '10%',
      align: 'center'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        align: 'center'
    },
    {
      title: 'Email_ID',
      dataIndex: 'email_id',
      key: 'email_id',
      width: '15%',
      align: 'center',
 
    },
    {
      title: 'Department',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: '15%',
      align: 'center',
  
    }
  ];
 
  const getData = async()=>{  
    toggleReportLoader(true)
    const { 
      recentlyAddEmployeesData
      } = await CALL_API('admin-dashboard', 'post', {
      method: 'recentlyAddEmployees',
    });
    setEmployeeList(recentlyAddEmployeesData)
    toggleReportLoader(false)    
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <StyledTitle>
          Recent Employees
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <StyledTable
            dataSource={employeeList}
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? '' :  'non-white'}
            rowKey='employeeID'
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

export default RecentEmployeeList;