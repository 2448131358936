import styled from "@emotion/styled";
import COLORS from "common/Colors";
import React from "react";

const StyledCircle = styled.span`
  background: ${COLORS.PRIMARY};
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: inline-block;
  svg {
    margin: 10px;
  }
`;

const RoundIcon = ({ children }) => {
  return (
    <StyledCircle className="styled-circle">
      {children}
    </StyledCircle>
  );
};

export default RoundIcon;