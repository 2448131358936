import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, message } from 'antd';
import Button from 'atoms/Button';
import moment from 'moment';
import styled from '@emotion/styled';
import { CSVLink } from "react-csv";
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getUserList
} from 'redux/selectors';

import { CALL_API } from 'common/API';
import { MONTH_LIST, STATUS_CODE } from 'common/Constants';

import { addDaysinDate, dateInDetail, getKenyanDateTime, getMonthDateRange, getMySqlDate, momentDate } from 'utils/Date';
import COLORS from 'common/Colors';
import { concat, find, get, includes, orderBy, reduce, map, flatMap, merge } from 'lodash';

const StyledTable = styled(Table)`
    padding-top: 20px;
    .ant-table-content {
        overflow-x: auto;
        max-height:500px;
        white-space: nowrap;
    }
    table {
        width: 100% !important;
    }
    .ant-table-cell {
        padding: 20px;
    }
    .ant-table-thead .ant-table-cell {
        font-weight: bold;
        color: ${COLORS.SECONDARY_BLACK}
    }
    .non-white {
        background: #f9f9f9;
    }
`;

const StyledApprovalSearchForm = styled(Form)`
    .ant-form-item-with-help {
        margin-bottom: 0px;
        .ant-form-item-explain-connected {
        display: none;
        }
    }
    .ant-form-item {
        display: inline-block;
    }
    .outer-form {
        margin-right: 0px;
    }
    .ant-select-selector {
        max-width: 180px;
        min-width: 180px;
        margin-bottom :10px;
        width: 180px;
    }
`;
const StyledButton = styled(Button)`
    width: 120px;
    border: 1px solid ${COLORS.PRIMARY};
    color:  ${COLORS.PRIMARY};
    width: initial;
    background:transparent;
`;

function MonthlyAttendanceReport({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  shiftTimeMaster
}) {

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [staffAttendanceList, setStaffAttendanceList] = useState([]);
  const [CSVAttendanceList, setCSVAttendanceList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [staffName, setStaffName] = useState(' ');
  const [filterList, setFilterList] = useState([]);
  const [monthList, setMonthList] = useState([])
  const [workingdays, setWorkingdays] = useState(0)

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }

  const STAFF_LIST_ALL = attendanceList.map((val) => {
    return { employeeId: val.employeeId, name: val.name }
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));

  const filterattentancereport = attendanceList.filter(val => {
    if (form.getFieldValue('staff_name') === '') {
      return val;
    }
    else if (val?.employeeId === form.getFieldValue('staff_name')) {
      return val;
    }
  })


  const CSVHeaders = [
    { label: "Staff ID" },
    { label: "First Name" },
    { label: "Last Name" },
    { label: "Department" },
    { label: "Total Working Days" },
    { label: "Worked days" },
    { label: "On-time" },
    { label: "Late" },
    { label: "Leave" },
    { label: "Absent" },
  ];

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: '7%',
      render: (employeeId) => {
        if (employeeId !== ("" || null || undefined)) {
          return employeeId;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '10%',
      render: (first_name) => {
        if (first_name !== ("" || null || undefined)) {
          return first_name;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '10%',
      render: (last_name) => {
        if (last_name !== ("" || null || undefined)) {
          return last_name;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Department',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      render: (department) => {
        if (department !== ("" || null || undefined)) {
          return department;

        } else {
          return "-"
        }
      }
    },
    {
      title: 'Total Working days',
      dataIndex: 'workingDaysValue',
      key: 'workingDaysValue',
      width: '10%',
    },
    {
      title: 'Worked days',
      dataIndex: 'Worked',
      key: 'Worked',
      width: '10%',
      render: (_, record) => {
        return record.ontime_count + record.late_count
      }
    },

    {
      title: 'On-Time',
      dataIndex: 'ontime_count',
      key: 'ontime_count',
      width: '10%',
    },
    {
      title: 'Late',
      dataIndex: 'late_count',
      key: 'late_count',
      width: '10%',
    },

    {
      title: 'Leave',
      dataIndex: 'LeaveCount',
      key: 'LeaveCount',
      width: '10%',
      render: (leave_count) => {
        if (leave_count == ("" || null || undefined)) {
          return "-";
        } else {
          return leave_count;
        }
      }
    },
    {
      title: 'Absent',
      dataIndex: 'absent_count',
      key: 'absent_count',
      width: '10%',
    },



  ];

  useEffect(() => {
    getAttendanceReport();
  }, [selectedDepartmentId, userRoleId, form.getFieldValue('duration_type'), form.getFieldValue('start_date'), form.getFieldValue('end_date'), form.getFieldValue('month'), form.getFieldValue('department')]);

  // Function to generate report
  const getAttendanceReport = async () => {
    list()
    return new Promise(async (resolve, reject) => {
      setAttendanceList([]);
      const {
        start_date,
        end_date,
        duration_type,
        month,
        department,
      } = form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), 'index', 0),
      );
      const {
        code,
        usersWiseAttendenceDetails,
        
      } = await CALL_API('attendance-report-monthly', 'post', {
        method: "userwiseLateOntimeAbsentCount",
        parameters: {
          department_id: (userRoleId === 1 || userRoleId === 6) ? [department] : selectedDepartmentId,
          startDate: getMySqlDate(startDateOfMonth),
          endDate: getMySqlDate(lastDateOfMonth),
        }
      });

      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {

        const transformedData = flatMap(usersWiseAttendenceDetails, ({ employeeDetail, ...rest }) => {
          return map(employeeDetail, employee => {
            const lateCountObj = find(rest.lateCountValue, { employeeId: employee.userId });
            const onTimeObj = find(rest.onTimeValue, { employeeId: employee.userId });
            const earlyCheckOutObj = find(rest.earlyCheckOutValue, { employeeId: employee.userId });
            const absentCountObj = find(rest.absentCountValue, { employeeId: employee.userId });
            const leaveCountObj = find(rest.leaveCountValue, { employeeId: employee.userId });
            return merge({}, employee, lateCountObj, onTimeObj, earlyCheckOutObj, absentCountObj, leaveCountObj, { workingDaysValue: rest.workingDaysValue });
          });
        });
        setDisplayTableLoader(false);
        setAttendanceList(orderBy(transformedData, ['date'], ['desc']));
        setStaffAttendanceList(orderBy(transformedData, ['date'], ['desc']));
      }
    })
  }

  // Function to validate date range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
  }
 
  // Function to prepare CSV
  const prepareCSV = async () => {
    if (filterattentancereport.length > 0) {
      setCSVAttendanceList(
        reduce(filterattentancereport, function (result, record) {
          result.push({
            "Employee ID": record.employeeId == "" ? "-" : record.employeeId,
            "First Name": record.first_name == "" ? "-" : record.first_name,
            "Last Name": record.last_name == "" ? "-" : record.last_name,
            "Department": record.department_name,
            "Total Working days": record.workingDaysValue,
            "Worked days": record.ontime_count + record.late_count,
            "On-Time": record.ontime_count,
            "Late": record.late_count,
            "Leave": record.LeaveCount == ("" || null || undefined) ? "-" : record.LeaveCount,
            "Absent": record.absent_count,
          });
          return result;
        }, [])
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      <Row className='page-title'>
        <Col xs={24} sm={19} md={19} className='page-title-head'>
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              initialValues={{
                staff_name: '',
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(getKenyanDateTime()),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(), 7)),
                department: '',
              }}
              onFinish={getAttendanceReport}
            >

              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Form.Item name='start_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='Start date'
                            // disabledDate={(current) => restrictPastDate(current, 'start_date')} 
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name='end_date'
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid() ? Promise.resolve() : Promise.reject();
                              }
                            })
                          ]}
                        >
                          <DatePicker
                            placeholder='End date'
                            // disabledDate={(current) => restrictPastDate(current, 'end_date')} 
                            onChange={validateDateRange}
                            allowClear={false}
                          // disabled={isViewOnly}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name='month'
                          rules={[
                            {
                              required: true,
                              message: '',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Select...'
                            options={monthList}
                          />
                        </Form.Item>
                      </>
                    )
                  }
                }
              </Form.Item>
              <Form.Item
                name='staff_name'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat({
                    employeeId: '',
                    name: 'All Employee'
                  }, STAFF_LIST)}
                  fieldNames={{
                    label: 'name',
                    value: 'employeeId'
                  }}
                />
              </Form.Item>
              {
                (userRoleId === 1 || userRoleId === 6) && (
                  <Form.Item
                    name='department'
                  >
                    <Select
                      placeholder="Select Department"
                      options={concat({
                        department_id: '',
                        department_name: 'All Departments'
                      }, allDepartmentList)}
                      fieldNames={{
                        label: 'department_name',
                        value: 'department_id'
                      }}
                    />
                  </Form.Item>
                )
              }
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={prepareCSV} disabled={attendanceList.length === 0}>
            Export CSV file
          </StyledButton>
          <CSVLink
            header={CSVHeaders}
            data={CSVAttendanceList}
            filename={"attendance-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterattentancereport}
          columns={columns}
          rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
          rowKey='checkin_id'
          pagination={filterattentancereport.length > 20 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state)
});

export default connect(mapStateToProps, null)(MonthlyAttendanceReport);