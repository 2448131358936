import React from "react";
import Privacy from "../assets/privacy&policy.svg";
import styled from "@emotion/styled";
import { Row, Col } from "antd";

const StyledRow = styled(Row)`
  padding-left: 5%;
  padding-top: 3%;
  padding-right: 5%;
  background-color: #5b86e5;
  img {
    max-width: 100%;
    height: auto;
  }
  .page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    margin-bottom: 5%;
    h1 {
      margin-top: 10px;
      color: #ffffff;
      letter-spacing: 2px;
      font-size: calc(3em + 1vw);
    }
    span {
      margin-top: -2%;
    }
  }
`;
const StyledContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 5%;
`;

const StyledContent = styled.div`
  padding-left: 5%;
  padding-top: 2%;
  padding-right: 5%;
  p {
    color: #000000;
    opacity: 1;
    word-spacing: 4px;
  }
`;

const StyledPolicyContent = styled.div`
  padding-left: 5%;
  padding-top: 2%;
  padding-right: 5%;
  h6 {
    color: #5b86e5;
    margin-bottom: 10px;
  }
`;

const policyData = [
  {
    title: "Information We Collect",
    Values: [
      {
        subTitle: "1.1 Personal Information",
        content:
          "We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and any other information you provide when contacting us, registering an account, or using our services.",
      },
      {
        subTitle: "1.2 Automatically Collected Information",
        content:
          "When you visit our website or use our services, we may automatically collect certain information, such as your IP address, browser type, device information, and usage data. This information is collected using various technologies, including cookies and similar tracking technologies.",
      },
    ],
  },
  {
    title: "Use of Information",
    Values: [
      {
        subTitle: "2.1 Providing and Improving Our Services",
        content:
          "We may use your personal information to communicate with you regarding our products, services, promotions, and updates. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the communication.",
      },
      {
        subTitle: "2.2 Communication",
        content:
          "When you visit our website or use our services, we may automatically collect certain information, such as your IP address, browser type, device information, and usage data. This information is collected using various technologies, including cookies and similar tracking technologies.",
      },
      {
        subTitle: "2.3 Data Analysis and Security",
        content:
          "We may use your information for data analysis, research, and to enhance the security and functionality of our website and services.",
      },
    ],
  },
  {
    title: "Information Sharing",
    Values: [
      {
        subTitle: "3.1 Third-Party Service Providers",
        content:
          "We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business, and providing services to you. These service providers are obligated to maintain the confidentiality and security of your information.",
      },
      {
        subTitle: "3.2 Legal Requirements",
        content:
          "We may disclose your personal information if required to do so by law or in response to valid requests from public authorities (e.g., government agencies, law enforcement).",
      },
      {
        subTitle: "3.3 Business Transfers",
        content:
          "In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website of any such change in ownership or control of your personal information.",
      },
    ],
  },
];

const majorData = [
  {
    Title: "Data Security",
    content:
      "We take appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
  },
  {
    Title: "Your Rights",
    content:
      "You have the right to access, correct, update, or delete your personal information. You may also have the right to object to or restrict certain processing of your information. To exercise these rights or inquire about the personal information we hold about you, please contact us using the contact details provided at the end of this Privacy Policy.",
  },
  {
    Title: "Changes to this Privacy Policy",
    content:
      "We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated Privacy Policy on our website or by other means of communication. Your continued use of our website or services after the effective date of the revised Privacy Policy constitutes your acceptance of the updated terms.",
  },
  {
    Title: "Contact Us",
    content:
      "If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at: tymeplus@gmail.com",
  },
];

function PrivacyAndPolicy() {
  return (
    <>
      <StyledContainer>
        <StyledRow gutter={8}>
          <Col md={12} sm={24} lg={12} className="page-title">
            <h1>Privacy policy</h1>
            <span>Our personal statement, cookies and third parties.</span>
          </Col>
          <Col md={12} sm={24} lg={12} className="align-center">
            <img src={Privacy} alt="privacy-policy" />
          </Col>
        </StyledRow>
        <StyledContent>
          <p>
            Tymeplus is the all-in-one tool that allows staff to keep track of
            their own performance through shift logging, location validation,
            overtime tracking, and task management. Plus staff can communicate
            with other team members through the app's instant messaging
            functionality, manage their leave balances and applications, and
            access important work documents on the go.
          </p>
        </StyledContent>
        {policyData.map((val) => {
          return (
            <StyledPolicyContent>
              <h6>{val.title}</h6>
              {val.Values.map((content) => {
                return (
                  <Row>
                    <Col md={24} sm={24} lg={6}>
                      <b>{content.subTitle}</b>
                    </Col>
                    <Col md={24} sm={24} lg={18}>
                      <p>{content.content}</p>
                    </Col>
                  </Row>
                );
              })}
            </StyledPolicyContent>
          );
        })}
        {majorData.map((val) => {
          return (
            <StyledPolicyContent>
              <h6>{val.Title}</h6>
              <p>{val.content}</p>
            </StyledPolicyContent>
          );
        })}
      </StyledContainer>
    </>
  );
}

export default PrivacyAndPolicy;
