import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Spin, Form, Select } from 'antd';
// import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import UserMonthlyReportDonut from '../organisms/UserMonthlyReportDonut';
import { LEAVE_ICON, USER_REPORT } from 'common/Constants';
import { CALL_API } from 'common/API';
import COLORS from 'common/Colors';
import { get,find, includes } from 'lodash'
import { dateInDetail, getMonthDateRange, getKenyanDateTime, getMySqlDate } from 'utils/Date';
import { MONTH_LIST, STATUS_CODE, } from 'common/Constants';
import moment from 'moment';


const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;
const StyledRow = styled(Row)`
  padding-top: 10px;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  margin-bottom: 15px;
`;
const StyledForm = styled(Form)`
   display: flex;
   justify-content: flex-end;
`;
function UserMonthlyReport() {
  const [ loading, setLoading ] = useState(false)
  const [ totalWorking, setTotalWorking] = useState('')
  const [ userReport, setUserReport ] = useState([
    { type: USER_REPORT.ONTIME, value: 0, color: COLORS. USER_REPORT_COLOR.ONTIME },
    { type: USER_REPORT.LATE, value: 0, color: COLORS. USER_REPORT_COLOR.LATE },
    { type: USER_REPORT.LEAVE, value: 0, color: COLORS. USER_REPORT_COLOR.LEAVE },
    { type: USER_REPORT.ABSENT, value: 0, color: COLORS. USER_REPORT_COLOR.ABSENT },
    { type: USER_REPORT.EARLYEXIT, value: 0, color: COLORS. USER_REPORT_COLOR.EARLYEXIT },
  ]);

  const [monthList, setMonthList] = useState([])
  const [ form ] = Form.useForm();

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
      console.log(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }
  const containerRef = useRef(null);

  const getCheckinData = async () => {
    const {
      month,
    } = form.getFieldsValue();
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month}), 'index', 0)
    );
    setLoading(true);
    const {usersWiseAttendenceDetails, code}= await CALL_API('attendance-report-per-user', 'post',{
      method: "userwiseLateOntimeAbsentCount",
      parameters: {
        startDate: getMySqlDate(startDateOfMonth),
        endDate: getMySqlDate(lastDateOfMonth),
     }
    });
    if (includes(STATUS_CODE.SUCCESS, code)){
      console.log("res---->,",usersWiseAttendenceDetails)
      let dupeLeaveData = Array.from(userReport);
      console.log("ontime--->",get(usersWiseAttendenceDetails[0], 'onTime', 0))
      dupeLeaveData[0].value = get(usersWiseAttendenceDetails[0], 'onTime', 0)
      dupeLeaveData[1].value = get(usersWiseAttendenceDetails[0], 'lateCount', 0)
      dupeLeaveData[2].value = get(usersWiseAttendenceDetails[0], 'leaveCount', 0)
      dupeLeaveData[3].value = get(usersWiseAttendenceDetails[0], 'absentCount', 0)
      dupeLeaveData[4].value = get(usersWiseAttendenceDetails[0], 'earlyCheckOut', 0)
      setTotalWorking(get(usersWiseAttendenceDetails[0], 'workingDays', 0))
      setUserReport(dupeLeaveData);
    }
   
    setLoading(false)
  }
  useEffect(() => {
    getCheckinData()
    list()
  }, [form.getFieldValue('month')])
  
  return (
    <>
      <StyledTitle>
          MONTHLY REPORT
      </StyledTitle>
      <StyledRow >
        <Col md={24} sm={24} xs={24} xl={24}  ref={containerRef}>
        <StyledForm
              form={form}
              name='approval_form'
              layout='inline'

              initialValues={{  
                month: dateInDetail(getKenyanDateTime()).monthName,
              }}    
            >
          <Form.Item
            name='month'
            rules={[
                {
                required: true,
                message: '',
                },
            ]}
           
            >
            <Select
                style={{
                width: 100,
                }}
                placeholder='Select...'
                options={monthList}
                onChange={getCheckinData}
                getPopupContainer={() => containerRef.current}
            />
        </Form.Item>
        </StyledForm>
          <Spin spinning={loading}>
            <UserMonthlyReportDonut title='MONTHLY REPORT' data={userReport} workingData={totalWorking}/>
          </Spin>
        </Col>
      </StyledRow>
    </>
  );
}


export default UserMonthlyReport;