import React, { useEffect, useState } from "react";
import EmployeeCard from "molecules/EmployeeCard";
import { Row, Col, Select, Button, Spin, Modal } from "antd";
import { DEVICE } from "common/Device";
import { connect } from "react-redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
} from "redux/selectors";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { map, concat, result, find } from "lodash";
import Icon from "atoms/Icon";
import AddEmployee from "organisms/AddEmployee";
import EditEmployee from "organisms/EditEmployee";
import Confirm from "../../src/assets/Confirm.svg";
import NotFound from "organisms/NotFound";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .selectbox {
    width: 100%;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledContainerRow = styled(Row)`
  padding-top: 20px;
  .loader_container {
    width: 100%;
    text-align: center;
  }
  .noEmployee {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 10%;
  }
`;

const StyledRoleCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5b86e5;
    border-radius: 5px;
    color: #ffffff;
    box-shadow: none;

    padding-left: 10px;
    cursor: pointer;
  }
  .add-title {
    margin-left: 10px;
    vertical-align: text-bottom;
  }

  @media ${DEVICE.tablet} {
    padding: 1rem;
    .role-button {
      width: 100%;
    }
  }
`;
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Employee({ userRoleId, allDepartmentList, selectedDepartmentId }) {
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [displayEditEmployeeModal, toggleEditEmployeeModal] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [displayEmployeeLoader, setdisplayEmployeeLoader] = useState(false);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [deactivateEmployee, toggledeactivateEmployee] = useState(false);
  const [confirmdeactivateEmployee, toggleconfirmdeactivateEmployee] =
    useState(false);
  const [empId, setEmpId] = useState(0);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [employeeFilterList, setEmployeeFilterList] = useState([]);
  const [employeeType, setEmployeeType] = useState(1);
  const [roleId, setRoleId] = useState("");
  const { Option } = Select;


  useEffect(() => {
    if (userRoleId !== 5) setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  const STAFF_LIST = employeeFilterList?.map((val) => {
    return { user_id: val?.EmployeeID, name: val?.name };
  });

  const sortedStaffList = [...STAFF_LIST];
  sortedStaffList.sort((a, b) => a.name.localeCompare(b.name));

  const getFilterList = () => {
    if (employeeNameFilter !== "") {
      return employeeFilterList?.filter(
        (data) => data?.EmployeeID === employeeNameFilter
      );
    } else {
      return employeeFilterList;
    }
  };

  useEffect(() => {
    var result;
    if (selectedDepartment != "") {
      if (roleId != "") {
        result = EmployeeList?.filter(
          (data) =>
            data?.staffDepartmentId == selectedDepartment &&
            data?.status_id === employeeType &&
            data?.roleId == roleId
        );
      } else {
        result = EmployeeList?.filter(
          (data) =>
            data?.staffDepartmentId == selectedDepartment &&
            data?.status_id === employeeType
        );
      }
    } else {
      if (roleId != "") {
        result = EmployeeList?.filter(
          (data) => data?.status_id === employeeType && data?.roleId == roleId
        );
      } else {
        result = EmployeeList?.filter(
          (data) => data?.status_id === employeeType
        );
      }
    }
    setEmployeeFilterList(result);
  }, [selectedDepartment, employeeType, EmployeeList, roleId]);

  useEffect(() => {
    getEmployeeList();
  }, [setEmployeeList]);

  const getEmployeeList = async () => {
    try {
      setdisplayEmployeeLoader(true);
      const response = await CALL_API("admin-employee", "get", {});
      setEmployeeList(response);
      setdisplayEmployeeLoader(false);
    } catch (error) {
      console.log("[getEmployeeList] Error-->", error);
    }
  };

  const ViewEmployee = (EmployeeObject, isViewOnly = false) => {
    toggleEditEmployeeModal(true);
    setEmployeeDetails({
      ...EmployeeObject,
      isViewOnly,
    });
  };

  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId);
  };

  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API("admin-employee", "delete", {
      userId: employee_id,
      status_id: 0,
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    getEmployeeList();
  };

  return (
    <>
      {userRoleId === 5 && (
        <StyledTitle className="page-title-head">List of Employees</StyledTitle>
      )}
      <StyledFilterRow gutter={16}>
        <Col md={4} sm={24} xs={24}>
          {userRoleId === 5 && (
            <Select
              className="selectbox"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Select Employee"
              options={concat(
                {
                  user_id: "",
                  name: "All Employee",
                },
                sortedStaffList
              )}
              fieldNames={{
                label: "name",
                value: "user_id",
              }}
              onChange={(value) => setEmployeeNameFilter(value)}
            />
          )}
        </Col>
        <Col md={4} sm={24} xs={24}>
          {userRoleId === 5 && (
            <Select
              className="selectbox"
              placeholder="Department"
              defaultValue={""}
              options={concat(
                {
                  department_id: "",
                  department_name: "All Departments",
                },
                allDepartmentList
              )}
              fieldNames={{
                label: "department_name",
                value: "department_id",
              }}
              onChange={(id) => setSelectedDepartment(id)}
            />
          )}
        </Col>
        <Col md={4} sm={24} xs={24}>
          {userRoleId === 5 && (
            <Select
              className="selectbox"
              placeholder="Employee type"
              defaultValue={1}
              options={[
                {
                  value: 1,
                  label: "Employee",
                },
                {
                  value: 0,
                  label: "Ex Employee",
                },
              ]}
              onChange={(id) => setEmployeeType(id)}
            />
          )}
        </Col>
        <Col md={4} sm={24} xs={24}>
          {userRoleId === 5 && (
            <Select
              placeholder="Select role"
              className="selectbox"
              onChange={(id) => setRoleId(id)}
            >
              <Option value="">All Role</Option>
              <Option value="1">Super HOD</Option>
              <Option value="2">HOD</Option>
              <Option value="3">ASSISTANT HOD</Option>
              <Option value="4">STAFF</Option>
              <Option value="6">MD</Option>
            </Select>
          )}
        </Col>
        <Col md={8} sm={24} xs={24} className="align-right">
          {userRoleId === 5 && (
            <StyledRoleCol>
              <Button
                onClick={() => toggleAddEmployeeModal(true)}
                className={`add-button`}
              >
                <Icon name="addemployee" />
                <span className="add-title">Add Employee</span>
              </Button>
            </StyledRoleCol>
          )}
        </Col>
      </StyledFilterRow>

      {userRoleId === 5 && (
        <StyledContainerRow gutter={16}>
          {displayEmployeeLoader ? (
            <div className="loader_container">
              <Spin spinning={displayEmployeeLoader} />
            </div>
          ) : (
            <>
              {employeeFilterList?.length > 0 ? (
                <>
                  {map(getFilterList(), (data, index) => (
                    <>
                      <StyledCol
                        key={index}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                      >
                        <EmployeeCard
                          id={data.EmployeeID}
                          name={data.name}
                          Department={data.roleId == 6 ? data.departmentName : data.staffDepartmentName }
                          Role={data.Role}
                          email_id={data.EmailId}
                          KRA_pin={data.KRA_pin}
                          NHIF={data.NHIF}
                          NSSF={data.NSSF}
                          employer_id={data.EmployeeID}
                          joining_date={data.joining_date}
                          date_of_birth={data.date_of_birth}
                          data={data}
                          getEmployeeList={getEmployeeList}
                          ViewEmployee={ViewEmployee}
                          deletedEmployee={deletedEmployee}
                          profile={data.profile_url}
                          dept_name={result(
                            find(allDepartmentList, (val) => {
                              return val.department_id === data.staffDepartmentId;
                            }),
                            "department_name"
                          )}
                        />
                      </StyledCol>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <p className="noEmployee">No Employee's in this Department</p>
                </>
              )}
            </>
          )}
        </StyledContainerRow>
      )}
      {userRoleId != 5 && <NotFound />}

      <AddEmployee
        isModalVisible={displayAddEmployeeModal}
        closeModal={() => toggleAddEmployeeModal(false)}
        getEmployeeList={getEmployeeList}
        setSelectedDepartment={setSelectedDepartment}
        EmployeeList={EmployeeList}
      />
      <EditEmployee
        isModalVisible={displayEditEmployeeModal}
        closeModal={() => toggleEditEmployeeModal(false)}
        getEmployeeList={getEmployeeList}
        EmployeeDetails={EmployeeDetails}
        EmployeeList={EmployeeList}
      />
      <StyleddeactivateModal
        title="Are you sure you want to terminate this Employee?"
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head" gutter={16}>
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateEmployee(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteEmployee(empId)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>

      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Employee);
